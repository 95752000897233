// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBw8VlMhcYuaESDeZ20ZRrAjXUWuNG3xEE",
  authDomain: "oglitz-software.firebaseapp.com",
  projectId: "oglitz-software",
  storageBucket: "oglitz-software.appspot.com",
  messagingSenderId: "875317218028",
  appId: "1:875317218028:web:70318ffee89338db90b3c9",
  measurementId: "G-6P821JZN4B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage, collection, addDoc, ref, uploadBytes, getDownloadURL };
