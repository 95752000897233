import React from "react";
import "./Company.css";
import imgMan from "../img/imgg.jpg";

const Company = () => {
  return (
    <div>
      <div className="company bg-gray-light">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-lg-8 text-center text-lg-left pl-lg-10 pl-md-2 order-2">
              <h2 className="mb-3 text-gray-medium h1">Become a partner</h2>
              <p>
                We collaborate with industry-leading partners to extend our
                capabilities with best-in-className solutions for growing their
                business with quality apps, great support, and unmatched value.
              </p>
              <a
                href="/contact"
                className="btn btn-outline-primary icon-btn projectBtn2"
              >
                Join the Program
                <i className="fa-solid fa-arrow-right-long"></i>
              </a>
            </div>
            <div className="col-12 col-lg-4 mb-7 mb-lg-0 pt-5 pt-lg-0 position-relative order-1 mitDivs">
              <div className="blob-image-1 mitDivs">
                <img
                  src={imgMan}
                  alt="Become a partner"
                  className="banner-img lazyautosizes ls-is-cached lazyloaded"
                  sizes="408px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="moreD">
        <div class="container">
          <div class="row align-items-center justify-content-between">
            <div class="col-12 col-lg-5 text-center text-lg-left">
              <h2 class="mb-3 font-weight-bold text-gray-medium display-4">
                Let's start your first project
              </h2>
            </div>
            <div class="col-12 col-lg-4 mt-6 mt-lg-0 text-center text-lg-right">
              <h3 class="mb-3 text-gray-medium">
                Click To Fill Your Project Details
              </h3>
              <a
                href="/start-project"
                class="btn btn-primary icon-btn projectBtn"
              >
                Start a Project <i className="fa-solid fa-arrow-right-long"></i>{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Company;
