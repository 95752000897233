import React, { useState } from "react";
import "./ContactFull.css";
import contactbox from "../img/contactbox.png";
import { toast } from "react-toastify";
import SEO from "../SEO";

const schemaContact = {
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "Oglitz Software Pvt Ltd.",
  "url": "https://www.oglitz.com",
  "logo": "https://www.oglitz.com/path/to/logo.png", // Update with your actual logo URL
  "contactPoint": {
    "@type": "ContactPoint",
    "contactType": "Customer Service",
    "telephone": "+91 95321 19007",
    "email": "info@oglitz.com",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "811/78 Yadav Nagar Ahirwan",
      "addressLocality": "Kanpur Nagar",
      "addressRegion": "Uttar Pradesh",
      "postalCode": "208007",
      "addressCountry": "IN"
    },
    "openingHours": "Mo-Fr 10:00-19:00", // Updated hours
    "faxNumber": "+91 95321 19008" // Optional, if applicable
  },
  "sameAs": [
    "https://www.instagram.com/oglitzsoftware",
    "https://x.com/OglitzSoftware",
    "https://www.linkedin.com/company/oglitz/",
    "https://www.facebook.com/profile.php?id=61565024655930"
  ],
  "additionalType": "https://schema.org/ContactPage",
  "description": "Oglitz Software Pvt Ltd. is a leading IT services company offering innovative technology solutions. Contact us for support, inquiries, and more information about our services.",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "811/78 Yadav Nagar Ahirwan",
    "addressLocality": "Kanpur Nagar",
    "addressRegion": "Uttar Pradesh",
    "postalCode": "208007",
    "addressCountry": "IN"
  }
};


function ContactFull() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [access_key, setaccess_key] = useState(
    "41c2c80f-1527-4cbd-8101-2f4c5dd07683"
  );
  const [organization, setOrganization] = useState("");

  const [formError, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  function saveUser(e) {
    e.preventDefault();
    if (name && email && message && access_key && phone && organization) {
      console.warn(name, email, message, access_key, phone, organization);

      let data = { subject: `New Contact Us Inquiry from ${name} - Oglitz Software`, name, email, message, access_key, phone, organization };
      fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then((result) => {
        // console.log("result", result)
        result.json().then((resp) => { });
      });

      setEmail("");
      setName("");
      setOrganization("");
      setMessage("");
      setPhone("");

      toast.success("Thank you for reaching out! Our team will be in touch shortly.");

      // setFormError(validationForm(name, organization, message, phone, email));
      // setIsSubmit(true);
    } else {
      toast.error("Please fill all the details. Thanks");
    }
  }

  const validationForm = (value) => {
    const errors = {};

    // const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!value.name) {
      errors.name = "Please enter  name";
    }
    if (!value.email) {
      errors.email = "Please enter  email";
    }
    if (!value.phone) {
      errors.phone = "Please enter  phone number";
    }
    if (!value.message) {
      errors.message = "Please enter  message";
    }
    if (!value.organization) {
      errors.organization = "Please enter  organization";
    }

    return errors;
  };

  const resetFormValue = () => {
    setEmail("");
    setName("");
    setOrganization("");
    setMessage("");
    setPhone("");
  };

  // useEffect(() => {
  //     if (Object.keys(formError).length === 0 && isSubmit) {

  //     }
  // }, [formError, isSubmit])

  return (
    <div>
      {/* <marquee
        width="100%"
        direction="left"
        height="50%"
        className="marquee-text"
      >
        🚧 This page is Under Maintenance(Contact Page ) 🚧
      </marquee> */}

      <SEO
        title="Contact Us | Oglitz Software Pvt Ltd."
        description="Get in touch with Oglitz Software Pvt Ltd. for any inquiries or support. Our contact page provides all the necessary information to reach out to us, including our office location, contact details, and a form to send us a message directly."
        image={contactbox} // Update with your actual contact page image URL
        url="https://www.oglitz.com/contact"
        keywords="Oglitz Software, contact us, IT services, support, technology solutions, reach out, customer service"
        author="Oglitz Software Pvt Ltd."
        schemaData={schemaContact} // Update with your specific schema data for the contact page if needed
      />

      <div className="s-banner mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-8 contact-line">
              <div className="ban-head-text">
                <h1>About Oglitz Software</h1>
                <p className="contact-about">
                  When it comes to designing and delivering the app for your
                  business, Oglitz with hands on technology and mobile
                  application development strives to extract maximum business
                  advantages for you.{" "}
                </p>
                <p className="contact-about">
                  When it comes to designing and delivering the app for your
                  business, Oglitz with hands on technology and mobile
                  application development strives to extract maximum business
                  advantages for you. When it comes to designing and delivering
                  the app for your business, Oglitz with hands on technology and
                  mobile application development strives to extract maximum
                  business advantages for you.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="ban-head-img">
                <img src={contactbox} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contactFull">
        <div className="title">
          <h2>Contact Us</h2>
        </div>

        <div className="box">
          <div className="contact form">
            <h3>Send a Message</h3>
            <form action="">
              <div className="formBox">
                <div className="formRow">
                  <div className="inputBox">
                    <span>Your Name</span>
                    <input
                      type="text"
                      placeholder="Enter your name "
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      name="name"
                      value={name}
                    />
                    <span className="error-message text-danger">
                      {formError.name}
                    </span>
                  </div>
                  <div className="inputBox">
                    <span>Email Address</span>
                    <input
                      type="email"
                      placeholder="Enter your email address"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      name="email"
                      value={email}
                    />

                    <span className="error-message text-danger">
                      {formError.email}
                    </span>
                  </div>
                </div>
                <div className="formRow">
                  <div className="inputBox">
                    <span>Organization</span>
                    <input
                      type="text"
                      placeholder="Enter your organization name "
                      onChange={(e) => {
                        setOrganization(e.target.value);
                      }}
                      name="organization"
                      value={organization}
                    />

                    <span className="error-message text-danger">
                      {formError.organization}
                    </span>
                  </div>
                  <div className="inputBox">
                    <span>Contact Number</span>
                    <input
                      type="number"
                      placeholder="Enter your mobile number"
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                      name="phone"
                      value={phone}
                    />

                    <span className="error-message text-danger">
                      {formError.phone}
                    </span>
                  </div>
                </div>
                <div className="formRow100">
                  <div className="inputBox">
                    <span>Message</span>
                    <textarea
                      placeholder="Write your message here..."
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      name="message"
                      value={message}
                    ></textarea>

                    <span className="error-message text-danger">
                      {formError.message}
                    </span>
                  </div>
                </div>
                <div className="formRow100">
                  <div className="inputBox">
                    <button type="submit" onClick={saveUser}>
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="contact info">
            <h3>Contact Info</h3>
            <div className="infoBox">
              <div>
                <span>
                  <ion-icon name="location"></ion-icon>
                </span>
                <p>
                  811/78 yadav nagar ahirwan, Katrisafipur, Kanpur Nagar, Uttar Pradesh - 208007
                  {/* 86 C Patel Nager, Harjinder Nagar Kanpur, Uttar Pradesh,208007 */}
                  {/* India */}
                  {/* <br /> */}
                </p>
              </div>
              <div>
                <span>
                  <ion-icon name="mail-open"></ion-icon>
                </span>
                <a href="mailto:info@oglitz.com" target="_blank">
                  info@oglitz.com
                </a>
              </div>
              <div>
                <span>
                  <ion-icon name="call"></ion-icon>
                </span>
                <a href="tel:+919532119007" target="_blank">
                  +91 95321 19007
                </a>
              </div>

              <ul className="smi">
                <li>
                  <a
                    href="https://instagram.com/oglitzsoftware?utm_source=qr&igshid=ZDc4ODBmNjlmNQ%3D%3D"
                    target="_blank"
                  >
                    <ion-icon name="logo-instagram"></ion-icon>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/ajay-tripathi-877b25236"
                    target="_blank"
                  >
                    <ion-icon name="logo-linkedin"></ion-icon>
                  </a>
                </li>
                {/* <li>
                  <a href="" target="_blank">
                    <ion-icon name="logo-twitter"></ion-icon>
                  </a>
                </li> */}
              </ul>
            </div>
          </div>

          <div className="contact map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d221.04867899562132!2d80.40619384144648!3d26.39092546436086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2sin!4v1725715377103!5m2!1sen!2sin"
              // width="400"
              // height="300"
              // style="border:0;"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>


          </div>

          <div className="contact map2">
            <iframe
              src="https://www.google.com/maps/embed?pb=!4v1725715521674!6m8!1m7!1s7uY2HJkMrAyqo0Dk7cYokA!2m2!1d26.39092546436086!2d80.40619384144648!3f138.36865!4f0!5f0.7820865974627469"
              // width="400"
              // height="300"
              // style="border:0;"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>


          </div>


        </div>
      </div>
    </div>
  );
}

export default ContactFull;
