import React from "react";
import "./CompanyInfo.css"; // Importing CSS for styling
import SEO from "./SEO";
import OglitzTechBlogIcon from "./img/Oglitz-main-icon.png";
import coverImage from "./img/coverImage.jpg";

const CompanyInfo = () => (
  <div className="company-info">
    <SEO
      title="Company Info - Oglitz Software"
      description="Learn more about Oglitz Software Pvt Ltd. Contact us for inquiries or support."
      image={coverImage}
      url="https://www.oglitz.com/?visit-software-hub"
      type="website"
    />
    <header className="company-info__header">
      <div className="company-info__banner-container">
        <img
          src={coverImage}
          alt="Cover Banner"
          className="company-info__banner"
        />
        <div className="company-info__overlay"></div>
        <div className="company-info__logo-container">
          {/* <img
            src={OglitzTechBlogIcon}
            alt="Company Logo"
            className="company-info__logo"
          /> */}
          <h1 className="company-info__name">Oglitz Software Pvt Ltd.</h1>
          <p>
            delivers innovative software solutions tailored to meet business
            needs
          </p>
        </div>
      </div>
    </header>
    <section className="company-info__details">
      {/* <h2>About Us</h2>
      <p>
        Oglitz Software Pvt Ltd. is a leading software development company
        specializing in innovative solutions for businesses. We focus on
        delivering high-quality software products that meet our clients' needs.
      </p> */}
      <h2>Contact Information</h2>
      <p>
        <strong>Email:</strong>{" "}
        <a href="mailto:info@oglitz.com">info@oglitz.com</a>
      </p>
      <p>
        <strong>Support Email:</strong>{" "}
        <a href="mailto:support@oglitz.com">support@oglitz.com</a>
      </p>
      <p>
        <strong>Phone Number:</strong>{" "}
        <a href="tel:+919532119007">+91 95321 19007</a>
      </p>
      <p>
        <strong>Website:</strong>{" "}
        <a
          href="https://www.oglitz.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          oglitz.com
        </a>
      </p>
      <div className="company-info__social">
        <a
          href="https://www.facebook.com/OglitzSoftware"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Facebook"
        >
          <i className="fa-brands fa-facebook-f"></i>
        </a>
        <a
          href="https://twitter.com/Oglitz"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Twitter"
        >
          <i className="fa-brands fa-twitter"></i>
        </a>
        <a
          href="https://www.linkedin.com/company/oglitz-software"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="LinkedIn"
        >
          <i className="fa-brands fa-linkedin"></i>
        </a>
        {/* Add more social media links here */}
      </div>
      <a href="/" className="company-info__back-button">
        Go to Home Page
      </a>
    </section>
  </div>
);

export default CompanyInfo;
