import React, { useState } from "react";
import "./Navbar.css";
import logo from "../img/Oglitz-main-icon.png";
// import logo from "../img/oglitz.png";
import { Link, NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";

const Navbar = () => {
  const [showNavbarItem, setShowNavbarItem] = useState(false);

  const menuClickButton = () => {
    setShowNavbarItem(!showNavbarItem);
  };

  return (
    <div className="navbar shadow-sm fixedNav">
      <div className="navbar-logo">
        {/* <h2>Oglitz Software</h2> */}
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>

      <div
        className={
          showNavbarItem ? "navbar-item mobile-menu-link" : "navbar-item"
        }
        id="navbar"
      >
        <ul
          className="text-gray-medium"
          onClick={() => setShowNavbarItem(false)}
        >
          <li>
            {" "}
            <NavLink to="/" className="navItem">
              Home
            </NavLink>
          </li>
          <li>
            {" "}
            <NavLink to="/about" className="navItem">
              About
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" className="navItem">
              Contact
            </NavLink>
          </li>
          {/* <li><NavLink to="/service" className="navItem">Services</NavLink></li> */}
          {/* <li>
            <NavLink to="/ourTeam" className="navItem">
              OurTeam
            </NavLink>
          </li> */}
          <li>
            <NavLink to="/project" className="navItem">
              Project
            </NavLink>
          </li>
          {/* <li>
            <a href="https://blog.oglitz.com/" target="_blank" className="navItem">
              Blogs
            </a>
          </li> */}
          <li>
            <NavLink to="/design" className="navItem">
              Design Pack
            </NavLink>
          </li>
        </ul>
      </div>
      {/* hamburget menu start code */}
      <div className="hamburget-menu">
        <div  onClick={menuClickButton}>
          <GiHamburgerMenu />
          {/* <i class="fa-solid fa-bars"></i> */}
        </div>
        {/* <a href="#" onClick={() => setShowNavbarItem(!showNavbarItem)}> */}
        {/* <GiHamburgerMenu /> */}
        {/* <i class="fa-solid fa-bars"></i> */}
        {/* </a> */}
      </div>

      {/* hamburget menu end code */}
    </div>
  );
};

export default Navbar;
