import React, { useState } from 'react';
import { Card, Button, Image, Header, Segment, Table } from 'semantic-ui-react';
import CustomModal from './Modal.js'; // Import the modal component
import { Link } from 'react-router-dom';

const PackageCard = ({ packageData }) => {
    const [modalShow, setModalShow] = useState(false);
    const [modalData, setModalData] = useState([]);

    const openRequsetModal = (data) => {
        setModalData(data)
        setModalShow(true)
    }

    return (
        <>
            <Card className="unique-package-card">
                <Image
                    src={packageData.logo}
                    alt={`${packageData.title} Logo`}
                    className="unique-package-logo"
                />
                <Card.Content>
                    <Card.Header className="unique-card-header">{packageData.title}</Card.Header>
                    <Card.Meta className="unique-card-meta">
                        ₹{packageData.price} | Duration: {packageData.duration}
                    </Card.Meta>
                    {/* <Segment className='whats-included-segment'>
                    <Header as='h3' className='whats-included-segmentHeading'>What's Included:</Header>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Feature</Table.HeaderCell>
                                <Table.HeaderCell>Detail</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {packageData.whatsIncluded.map((item, index) => (
                                <Table.Row key={index}>
                                    <Table.Cell>{item.feature}</Table.Cell>
                                    <Table.Cell>{item.detail}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Segment> */}
                    {/* <Card.Description className="unique-card-description">
                        <strong>Benefits:</strong><br />
                        {packageData.benefits}
                    </Card.Description> */}
                    <Card.Description className="unique-card-description">
                        {packageData.description}
                    </Card.Description>
                    <Card.Description className="unique-card-extra">
                        <strong>More Info:</strong><br />
                        {packageData.moreInfo}
                    </Card.Description>
                </Card.Content>
                <Card.Content extra>
                    <div className="button-container">
                        <Link
                            to={packageData.link}
                            className="unique-link-button"
                        // onClick={() => window.open(packageData.link, "_blank")}
                        >
                            More Details/Demo
                        </Link>
                        <Button
                            className="unique-card-button"
                            onClick={() => openRequsetModal(packageData)}
                        >
                            Request Order
                        </Button>
                    </div>
                </Card.Content>
            </Card>

            <CustomModal
                isOpen={modalShow}
                onRequestClose={() => setModalShow(false)}
                modalData={modalData}
            />
        </>
    );
};

export default PackageCard;
