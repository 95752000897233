import React from "react";
import "./About.css";
import rightImg from "../img/imgg.jpg";
import b1 from "../img/b1.png";
import b2 from "../img/b2.png";
import b3 from "../img/b3.png";
import b4 from "../img/b4.png";
import b5 from "../img/b5.png";
import SEO from "../SEO";

const schemaPackages = {
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "Oglitz Software Pvt Ltd.",
  "url": "https://www.oglitz.com",
  "logo": "https://www.oglitz.com/static/media/Oglitz-main-icon.6ad376319eba01801745.png",
  "description": "Oglitz Software Pvt Ltd. delivers innovative software solutions tailored to meet business needs.",
  "contactPoint": [
    {
      "@type": "ContactPoint",
      "contactType": "Customer Service",
      "telephone": "+91-95321-19007",
      "email": "info@oglitz.com",
      "availableLanguage": "English"
    },
    {
      "@type": "ContactPoint",
      "contactType": "Support",
      "telephone": "+91-95321-19007",
      "email": "support@oglitz.com",
      "availableLanguage": "English"
    },
    {
      "@type": "ContactPoint",
      "contactType": "WhatsApp",
      "telephone": "+91-95321-19007",
      "url": "https://wa.me/919532119007",
      "availableLanguage": "English"
    }
  ],
  "sameAs": [
    "https://www.instagram.com/oglitzsoftware",
    "https://x.com/OglitzSoftware",
    "https://www.linkedin.com/company/oglitz/",
    "https://www.facebook.com/profile.php?id=61565024655930"
  ],
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "811/78 Yadav Nagar, Ahirwan",
    "addressLocality": "Katrisafipur",
    "addressRegion": "Kanpur Nagar",
    "addressCountry": "IN",
    "postalCode": "208007"
  },
  "founder": {
    "@type": "Person",
    "name": "Ajay Mani Tripathi",
    "jobTitle": "Founder and Director"
  },
  "about": {
    "@type": "Text",
    "text": "Oglitz is more than just a name; it embodies our core values and mission. The name 'Oglitz' is a fusion of O and Glitz: - O: Represents ॐ (OM), symbolizing unity, completeness, and the universal connection of all beings. - Glitz: Signifies brilliance, sophistication, and excellence in the IT industry. Together, Oglitz reflects our commitment to bringing together comprehensive and innovative solutions with a touch of elegance and professionalism. Our name represents our dedication to providing top-notch IT services while fostering a deep understanding and connection with our clients."
  }
}


const About = () => {
  return (
    <>
      <SEO
        title="About Us | Oglitz Software Pvt Ltd."
        description="Learn more about Oglitz Software Pvt Ltd., a leading IT services company committed to delivering innovative and reliable technology solutions. Discover our values, mission, and the meaning behind our name."
        image={rightImg} // Update with your actual image URL
        url="https://www.oglitz.com/about"
        keywords="Oglitz Software, IT services company, technology solutions, innovative IT solutions, professional IT services, Oglitz Software Pvt Ltd."
        author="Oglitz Software Pvt Ltd."
        schemaData={schemaPackages} // Update with your specific schema data if needed
      />

      <div className="about">
        <div className="about-container">
          <div className="about-left">
            <h1 className="title">Oglitz / ओग्लिट्ज़</h1>
            <p className="sub-title">Since 2021 - Certified ISO in Process</p>
            <div className="socialItems">
              <a
                href="https://www.instagram.com/oglitzsoftware/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a
                href="https://twitter.com/OglitzSoftware"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-twitter"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/oglitz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-linkedin"></i>
              </a>
            </div>
            <div className="about-line">
              <p>
                <strong>Oglitz Software</strong> is a forward-thinking global IT
                services company dedicated to transforming business growth with
                cutting-edge technologies. Our mission is to revolutionize the way
                organizations achieve their goals by leveraging innovative and
                emerging technologies.
                <br />
                <br />
                With a proven track record of delivering customized and highly
                reliable IT solutions, Oglitz Software has experienced remarkable
                growth and success over the years. We operate on a global scale,
                offering a unique approach to IT services and solutions that
                distinguishes us from the competition.
                <br />
                <br />
                Our commitment to excellence is reflected in our dedicated
                engagement model, which ensures that we not only meet our clients'
                needs but also support them throughout the entire IT development
                process. By deeply understanding our clients and their businesses,
                we provide swift and targeted solutions, enhancing their
                operational efficiency.
                <br />
                <br />
                Oglitz Software continuously strives to integrate the best
                technologies with a professional approach, earning the trust of
                numerous local and global companies for their IT development
                needs.
              </p>
            </div>
            <div className="oglitze-meaning">
              <h2>The Meaning of Oglitz</h2>
              <p>
                <strong>Oglitz</strong> is more than just a name; it embodies our
                core values and mission. The name "Oglitz" is a fusion of{" "}
                <strong>O</strong> and <strong>Glitz</strong>:
                <br />
                <br />- <strong>O</strong>: Represents <strong>ॐ</strong> (OM),
                symbolizing unity, completeness, and the universal connection of
                all beings.
                <br />- <strong>Glitz</strong>: Signifies brilliance,
                sophistication, and excellence in the IT industry.
                <br />
                <br />
                Together, <strong>Oglitz</strong> reflects our commitment to
                bringing together comprehensive and innovative solutions with a
                touch of elegance and professionalism. Our name represents our
                dedication to providing top-notch IT services while fostering a
                deep understanding and connection with our clients.
              </p>
            </div>
          </div>

          <div className="about-right">
            <div className="right-img">
              <img src={rightImg} alt="Oglitz Software" />
            </div>
          </div>
        </div>

        <div className="about-review">
          <div className="blue-banner abt-blues">
            <div className="banner-content">
              <div className="row pt-2 mt-3 pt-md-5">
                <div className="blue-circle">
                  <img src={b1} className="img-fluid" alt="Projects Delivered" />
                  <h2>
                    <span className="counter">60</span>
                  </h2>
                  <p>Projects Delivered</p>
                </div>

                <div className="blue-circle">
                  <img src={b2} className="img-fluid" alt="Years of Experience" />
                  <h2>
                    <span className="counter">4</span>+
                  </h2>
                  <p>Years of Experience</p>
                </div>

                <div className="blue-circle">
                  <img src={b3} className="img-fluid" alt="Satisfied Clients" />
                  <h2>
                    <span className="counter">61</span>
                  </h2>
                  <p>Satisfied Clients</p>
                </div>

                <div className="blue-circle">
                  <img src={b4} className="img-fluid" alt="Skilled Experts" />
                  <h2>
                    <span className="counter">20</span>
                  </h2>
                  <p>Skilled Experts</p>
                </div>

                <div className="blue-circle">
                  <img
                    src={b5}
                    className="img-fluid"
                    alt="Countries Client Served"
                  />
                  <h2>
                    <span className="counter">25</span>
                  </h2>
                  <p>Countries Served</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
