import React from "react";
import Company from "../Company/Company";
import "./Card.css";
import { NavLink } from "react-router-dom";

const Card = () => {
  return (
    <div className="cardBox">
      <section className="">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 col-md-10  mb-4 text-center">
              <h2 className="h1 text-gray-medium mb-3">OUR SERVICES</h2>
              <p className="text-black mb-0 text-18 pSmall">
                Our array of high quality digital services and solutions can
                transform your ideas into high end business reality.
                <br />
                Our experience in harnessing technological innovations has
                helped more than 300 companies to realize unparalleled growth.
                Join us in this digital revolution and give wings to your
                business.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="d-flex equal-height col-12 col-sm-6 col-lg-4 my-3">
              <div className="card hover-card border-0 shadow p-30">
                <div className="card-body p-0">
                  <div className="d-flex align-items-center align-items-sm-baseline flex-column mb-3">
                    <span className="bg-lightgreen topapps-iconbox mb-4">
                      <NavLink to="/start-project"
                        target="_top"
                        aria-label="apps"
                        className="d-block"
                      >
                        <img
                          src="https://cdn.shopify.com/s/files/1/1564/7647/files/ic_hulkapps.svg?v=1646372329"
                          alt="Oglitz"
                          className="img-fluid"
                        />
                      </NavLink>
                    </span>
                    <NavLink to="/start-project" aria-label="apps" className="h3 mb-0 ">
                      {/* <span className="text-black">Oglitz</span> */}
                      {/* <i className="text-hulkgreen">/apps&gt;</i> */}
                      <i className="text-hulkgreen">DESIGN & UI/UX</i>
                    </NavLink>
                  </div>
                  <p className=" text-sm-left">
                    We transform businesses through our innovative UI and UX
                    designs and concepts.
                  </p>
                </div>
                <div className="card-footer border-top-0 bg-white p-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2">
                      <NavLink to="/start-project"
                        target="_top"
                        className="text-hulkgreen text-16 font-weight-bold"
                      >
                        Get Started
                      </NavLink>
                      <p className="mb-0 text-12"></p>
                    </div>
                    <NavLink to="/start-project"
                      target="_top"
                      aria-label="patterns"
                      className="h1 mb-0 arrow-icon "
                    >
                      <i className="fa-solid fa-arrow-right-long text-hulkgreen"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex equal-height col-12 col-sm-6 col-lg-4 my-3">
              <div className="card hover-card border-0 shadow p-30">
                <div className="card-body p-0">
                  <div className="d-flex align-items-center align-items-sm-baseline flex-column mb-3">
                    <span className="bg-lightpeach topapps-iconbox mb-4">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        aria-label="packages"
                        className="d-block"
                      >
                        <img
                          src="https://cdn.shopify.com/s/files/1/1564/7647/files/ic_hulk_packages.svg?v=1646372330"
                          alt="Oglitz"
                          className="img-fluid"
                        />
                      </NavLink>
                    </span>
                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="packages"
                      className="h3 mb-0"
                    >
                      {/* <span className="text-black">Oglitz
                                            </span> */}
                      {/* <i className="text-hulkpeach">/packages&gt;
                                            </i> */}
                      <i className="text-hulkpeach">MOBILE APPLICATIONS</i>
                    </NavLink>
                  </div>
                  <p className=" text-sm-left">
                    We deliver high performing, highly scalable and versatile
                    mobile applications, consistently.
                  </p>
                </div>{" "}
                <div className="card-footer border-top-0 bg-white p-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        className="text-hulkpeach text-16 font-weight-bold"
                      >
                        Get Started
                      </NavLink>{" "}
                      <p className="mb-0 text-12"></p>
                    </div>

                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="patterns"
                      className="h1 mb-0 arrow-icon"
                    >
                      <i className="fa-solid fa-arrow-right-long text-hulkpeach"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex equal-height col-12 col-sm-6 col-lg-4 my-3">
              <div className="card hover-card border-0 shadow p-30">
                <div className="card-body p-0">
                  <div className="d-flex align-items-center align-items-sm-baseline flex-column mb-3">
                    <span className="bg-lightdarkpurple topapps-iconbox mb-4">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        aria-label="services"
                        className="d-block"
                      >
                        <img
                          src="https://cdn.shopify.com/s/files/1/1564/7647/files/ic_hulk_services.svg?v=1646372330"
                          alt="Ogiltz Software - services"
                          className="img-fluid"
                        />
                      </NavLink>
                    </span>
                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="services"
                      className="h3 mb-0"
                    >
                      {/* <span className="text-black">Oglitz
                                            </span> */}
                      {/* <i className="text-hulkdarkpurple">/services&gt;</i> */}
                      <i className="text-hulkdarkpurple">
                        E-COMMERCE SOLUTIONS
                      </i>
                    </NavLink>
                  </div>
                  <p className="text-sm-left">
                    We provide next generation e-commerce innovations to take
                    your e-store to the next level.
                  </p>
                </div>
                <div className="card-footer border-top-0 bg-white p-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        className="text-hulkdarkpurple text-16 font-weight-bold"
                      >
                        Get Started
                      </NavLink>
                      <p className="mb-0 text-12"></p>
                    </div>
                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="patterns"
                      className="h1 mb-0 arrow-icon"
                    >
                      <i className="fa-solid fa-arrow-right-long text-hulkdarkpurple"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex equal-height col-12 col-sm-6 col-lg-4 my-3">
              <div className="card hover-card border-0 shadow p-30">
                <div className="card-body p-0">
                  <div className="d-flex align-items-center align-items-sm-baseline flex-column mb-3">
                    <span className="bg-lightgrassy topapps-iconbox mb-4">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        aria-label="mobile"
                        className="d-block"
                      >
                        <img
                          src="https://cdn.shopify.com/s/files/1/1564/7647/files/ic_hulk_mobile.svg?v=1615464056"
                          alt="Ogiltz Software - mobile"
                          className="img-fluid"
                        />
                      </NavLink>
                    </span>
                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="mobile"
                      className="h3 mb-0"
                    >
                      {/* <span className="text-black">Oglitz
                                            </span> */}
                      {/* <i className="text-hulkgrassy">/mobile&gt;
                                            </i> */}
                      <i className="text-hulkgrassy">WEB DEVELOPMENT</i>
                    </NavLink>
                  </div>
                  <p className="text-sm-left">
                    We create beautiful, scalable and fully customizable web
                    apps to create exceptional web experiences.
                  </p>
                </div>
                <div className="card-footer border-top-0 bg-white p-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        className="text-hulkgrassy text-16 font-weight-bold"
                      >
                        Get Started
                      </NavLink>
                      <p className="mb-0 text-12"></p>
                    </div>

                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="patterns"
                      className="h1 mb-0 arrow-icon"
                    >
                      <i className="fa-solid fa-arrow-right-long text-hulkgrassy"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex equal-height col-12 col-sm-6 col-lg-4 my-3">
              <div className="card hover-card border-0 shadow p-30">
                <div className="card-body p-0">
                  <div className="d-flex align-items-center align-items-sm-baseline flex-column mb-3">
                    <span className="bg-lightindigoblue topapps-iconbox mb-4">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        aria-label="patterns"
                        className="d-block"
                      >
                        <img
                          src="https://cdn.shopify.com/s/files/1/1564/7647/files/ic_hulk_patterns.svg?v=1615464056"
                          alt="Ogiltz Software - patterns"
                          className="img-fluid"
                        />
                      </NavLink>
                    </span>
                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="patterns"
                      className="h3 mb-0"
                    >
                      {/* <span className="text-black">Oglitz
                                            </span> */}
                      {/* <i className="text-hulkindigoblue">/patterns&gt;
                                            </i> */}
                      <i className="text-hulkindigoblue">
                        REACT JS APPLICATION
                      </i>
                    </NavLink>
                  </div>
                  <p className="text-sm-left">
                    We Provide single page Application To increase performance.
                    And attract more customer. Using React Js
                  </p>
                </div>
                <div className="card-footer border-top-0 bg-white p-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        className="text-hulkindigoblue text-16 font-weight-bold"
                      >
                        Get Started
                      </NavLink>
                      <p className="mb-0 text-12"></p>
                    </div>
                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="patterns"
                      className="h1 mb-0 arrow-icon"
                    >
                      <i className="fa-solid fa-arrow-right-long text-hulkindigoblue"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex equal-height col-12 col-sm-6 col-lg-4 my-3">
              <div className="card hover-card border-0 shadow p-30">
                <div className="card-body p-0">
                  <div className="d-flex align-items-center align-items-sm-baseline flex-column mb-3">
                    <span className="bg-lightskyblue topapps-iconbox mb-4">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        aria-label="partners"
                        className="d-block"
                      >
                        <img
                          src="https://cdn.shopify.com/s/files/1/1564/7647/files/ic_hulk_partners.svg?v=1615464056"
                          alt="Ogiltz Software - partners"
                          className="img-fluid"
                        />
                      </NavLink>
                    </span>
                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="partners"
                      className="h3 mb-0"
                    >
                      {/* <span className="text-black">Oglitz
                                            </span> */}
                      {/* <i className="text-hulkskyblue">/partners&gt;
                                            </i> */}
                      <i className="text-hulkskyblue">APPLICATION MAITENANCE</i>
                    </NavLink>
                  </div>
                  <p className="text-sm-left">
                    We provide Your appliation Maintenance and services. For
                    your Existing Application
                  </p>
                </div>
                <div className="card-footer border-top-0 bg-white p-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        className="text-hulkskyblue text-16 font-weight-bold"
                      >
                        Get Started
                      </NavLink>
                      <p className="mb-0 text-12"></p>
                    </div>
                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="partners"
                      className="h1 mb-0 arrow-icon"
                    >
                      <i className="fa-solid fa-arrow-right-long text-hulkskyblue"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex equal-height col-12 col-sm-6 col-lg-4 my-3">
              <div className="card hover-card border-0 shadow p-30">
                <div className="card-body p-0">
                  <div className="d-flex align-items-center align-items-sm-baseline flex-column mb-3">
                    <span className="bg-lightgreen topapps-iconbox mb-4">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        aria-label="apps"
                        className="d-block"
                      >
                        <img
                          src="https://cdn.shopify.com/s/files/1/1564/7647/files/ic_hulkapps.svg?v=1646372329"
                          alt="Ogiltz Software - apps"
                          className="img-fluid"
                        />
                      </NavLink>
                    </span>
                    <NavLink to="/design" aria-label="apps" className="h3 mb-0">
                      {/* <span className="text-black">Oglitz</span> */}
                      {/* <i className="text-hulkgreen">/apps&gt;</i> */}
                      <i className="text-hulkgreen"> SOFTWARE TESTING</i>
                    </NavLink>
                  </div>
                  <p className=" text-sm-left">
                    We provide Software Testing For Existing Applications. That
                    help to grow your Business
                  </p>
                </div>
                <div className="card-footer border-top-0 bg-white p-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2">
                      <NavLink
                        to="/start-project"
                        className="text-hulkgreen text-16 font-weight-bold"
                      >
                        Get Started
                      </NavLink>
                      <p className="mb-0 text-12"></p>
                    </div>
                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="patterns"
                      className="h1 mb-0 arrow-icon"
                    >
                      <i className="fa-solid fa-arrow-right-long text-hulkgreen"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex equal-height col-12 col-sm-6 col-lg-4 my-3">
              <div className="card hover-card border-0 shadow p-30">
                <div className="card-body p-0">
                  <div className="d-flex align-items-center align-items-sm-baseline flex-column mb-3">
                    <span className="bg-lightpeach topapps-iconbox mb-4">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        aria-label="packages"
                        className="d-block"
                      >
                        <img
                          src="https://cdn.shopify.com/s/files/1/1564/7647/files/ic_hulk_packages.svg?v=1646372330"
                          alt="Ogiltz Software - packages"
                          className="img-fluid"
                        />
                      </NavLink>
                    </span>
                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="packages"
                      className="h3 mb-0"
                    >
                      {/* <span className="text-black">Oglitz
                                            </span> */}
                      {/* <i className="text-hulkpeach">/packages&gt;
                                            </i> */}
                      <i className="text-hulkpeach">
                        Search Engine Optimization (SEO)
                      </i>
                    </NavLink>
                  </div>
                  <p className=" text-sm-left">
                    We Provide Best SEO That help To rank Your Applications on
                    google
                  </p>
                </div>{" "}
                <div className="card-footer border-top-0 bg-white p-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        className="text-hulkpeach text-16 font-weight-bold"
                      >
                        Get Started
                      </NavLink>{" "}
                      <p className="mb-0 text-12"></p>
                    </div>

                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="patterns"
                      className="h1 mb-0 arrow-icon"
                    >
                      <i className="fa-solid fa-arrow-right-long text-hulkpeach"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex equal-height col-12 col-sm-6 col-lg-4 my-3">
              <div className="card hover-card border-0 shadow p-30">
                <div className="card-body p-0">
                  <div className="d-flex align-items-center align-items-sm-baseline flex-column mb-3">
                    <span className="bg-lightdarkpurple topapps-iconbox mb-4">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        aria-label="services"
                        className="d-block"
                      >
                        <img
                          src="https://cdn.shopify.com/s/files/1/1564/7647/files/ic_hulk_services.svg?v=1646372330"
                          alt="Ogiltz Software - services"
                          className="img-fluid"
                        />
                      </NavLink>
                    </span>
                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="services"
                      className="h3 mb-0"
                    >
                      {/* <span className="text-black">Oglitz
                                            </span> */}
                      {/* <i className="text-hulkdarkpurple">/services&gt;</i> */}
                      <i className="text-hulkdarkpurple">
                        Android App Development
                      </i>
                    </NavLink>
                  </div>
                  <p className="text-sm-left">
                    We Provide Android Native Application Using Java or Kotlin
                    and Provide Very scalable efficient access Application
                  </p>
                </div>
                <div className="card-footer border-top-0 bg-white p-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        className="text-hulkdarkpurple text-16 font-weight-bold"
                      >
                        Get Started
                      </NavLink>
                      <p className="mb-0 text-12"></p>
                    </div>
                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="patterns"
                      className="h1 mb-0 arrow-icon"
                    >
                      <i className="fa-solid fa-arrow-right-long text-hulkdarkpurple"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex equal-height col-12 col-sm-6 col-lg-4 my-3">
              <div className="card hover-card border-0 shadow p-30">
                <div className="card-body p-0">
                  <div className="d-flex align-items-center align-items-sm-baseline flex-column mb-3">
                    <span className="bg-lightgrassy topapps-iconbox mb-4">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        aria-label="mobile"
                        className="d-block"
                      >
                        <img
                          src="https://cdn.shopify.com/s/files/1/1564/7647/files/ic_hulk_mobile.svg?v=1615464056"
                          alt="Ogiltz Software - mobile"
                          className="img-fluid"
                        />
                      </NavLink>
                    </span>
                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="mobile"
                      className="h3 mb-0"
                    >
                      {/* <span className="text-black">Oglitz
                                            </span> */}
                      {/* <i className="text-hulkgrassy">/mobile&gt;
                                            </i> */}
                      <i className="text-hulkgrassy">Website Designing</i>
                    </NavLink>
                  </div>
                  <p className="text-sm-left">
                    we help to create beautiful, scalable and fully customizable
                    responsive design to create exceptional experiences.
                  </p>
                </div>
                <div className="card-footer border-top-0 bg-white p-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        className="text-hulkgrassy text-16 font-weight-bold"
                      >
                        Get Started
                      </NavLink>
                      <p className="mb-0 text-12"></p>
                    </div>

                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="patterns"
                      className="h1 mb-0 arrow-icon"
                    >
                      <i className="fa-solid fa-arrow-right-long text-hulkgrassy"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex equal-height col-12 col-sm-6 col-lg-4 my-3">
              <div className="card hover-card border-0 shadow p-30">
                <div className="card-body p-0">
                  <div className="d-flex align-items-center align-items-sm-baseline flex-column mb-3">
                    <span className="bg-lightindigoblue topapps-iconbox mb-4">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        aria-label="patterns"
                        className="d-block"
                      >
                        <img
                          src="https://cdn.shopify.com/s/files/1/1564/7647/files/ic_hulk_patterns.svg?v=1615464056"
                          alt="Ogiltz Software - patterns"
                          className="img-fluid"
                        />
                      </NavLink>
                    </span>
                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="patterns"
                      className="h3 mb-0"
                    >
                      {/* <span className="text-black">Oglitz
                                            </span> */}
                      {/* <i className="text-hulkindigoblue">/patterns&gt;
                                            </i> */}
                      <i className="text-hulkindigoblue">
                        react native APPLICATION
                      </i>
                    </NavLink>
                  </div>
                  <p className="text-sm-left">
                    React Native App Provide Cross Platform Application. It is
                    very scalable or price efficient for your business
                  </p>
                </div>
                <div className="card-footer border-top-0 bg-white p-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        className="text-hulkindigoblue text-16 font-weight-bold"
                      >
                        Get Started
                      </NavLink>
                      <p className="mb-0 text-12"></p>
                    </div>
                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="patterns"
                      className="h1 mb-0 arrow-icon"
                    >
                      <i className="fa-solid fa-arrow-right-long text-hulkindigoblue"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex equal-height col-12 col-sm-6 col-lg-4 my-3">
              <div className="card hover-card border-0 shadow p-30">
                <div className="card-body p-0">
                  <div className="d-flex align-items-center align-items-sm-baseline flex-column mb-3">
                    <span className="bg-lightskyblue topapps-iconbox mb-4">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        aria-label="partners"
                        className="d-block"
                      >
                        <img
                          src="https://cdn.shopify.com/s/files/1/1564/7647/files/ic_hulk_partners.svg?v=1615464056"
                          alt="Ogiltz Software - partners"
                          className="img-fluid"
                        />
                      </NavLink>
                    </span>
                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="partners"
                      className="h3 mb-0"
                    >
                      {/* <span className="text-black">Oglitz
                                            </span> */}
                      {/* <i className="text-hulkskyblue">/partners&gt;
                                            </i> */}
                      <i className="text-hulkskyblue">flutter APPLICATION</i>
                    </NavLink>
                  </div>
                  <p className="text-sm-left">
                    We provide Cross Application that run both iOS and Android
                    with the help of flutter Technologies
                  </p>
                </div>
                <div className="card-footer border-top-0 bg-white p-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        className="text-hulkskyblue text-16 font-weight-bold"
                      >
                        Get Started
                      </NavLink>
                      <p className="mb-0 text-12"></p>
                    </div>
                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="partners"
                      className="h1 mb-0 arrow-icon"
                    >
                      <i className="fa-solid fa-arrow-right-long text-hulkskyblue"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex equal-height col-12 col-sm-6 col-lg-4 my-3">
              <div className="card hover-card border-0 shadow p-30">
                <div className="card-body p-0">
                  <div className="d-flex align-items-center align-items-sm-baseline flex-column mb-3">
                    <span className="bg-lightgrassy topapps-iconbox mb-4">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        aria-label="mobile"
                        className="d-block"
                      >
                        <img
                          src="https://cdn.shopify.com/s/files/1/1564/7647/files/ic_hulk_mobile.svg?v=1615464056"
                          alt="Ogiltz Software - mobile"
                          className="img-fluid"
                        />
                      </NavLink>
                    </span>
                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="mobile"
                      className="h3 mb-0"
                    >
                      {/* <span className="text-black">Oglitz
                                            </span> */}
                      {/* <i className="text-hulkgrassy">/mobile&gt;
                                            </i> */}
                      <i className="text-hulkgrassy"> Website Redesigning</i>
                    </NavLink>
                  </div>
                  <p className="text-sm-left">
                    We help to Redesigning beautiful and fully customizable
                    responsive to create exceptional experiences.
                  </p>
                </div>
                <div className="card-footer border-top-0 bg-white p-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        className="text-hulkgrassy text-16 font-weight-bold"
                      >
                        Get Started
                      </NavLink>
                      <p className="mb-0 text-12"></p>
                    </div>

                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="patterns"
                      className="h1 mb-0 arrow-icon"
                    >
                      <i className="fa-solid fa-arrow-right-long text-hulkgrassy"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex equal-height col-12 col-sm-6 col-lg-4 my-3">
              <div className="card hover-card border-0 shadow p-30">
                <div className="card-body p-0">
                  <div className="d-flex align-items-center align-items-sm-baseline flex-column mb-3">
                    <span className="bg-lightindigoblue topapps-iconbox mb-4">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        aria-label="patterns"
                        className="d-block"
                      >
                        <img
                          src="https://cdn.shopify.com/s/files/1/1564/7647/files/ic_hulk_patterns.svg?v=1615464056"
                          alt="Ogiltz Software - patterns"
                          className="img-fluid"
                        />
                      </NavLink>
                    </span>
                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="patterns"
                      className="h3 mb-0"
                    >
                      {/* <span className="text-black">Oglitz
                                            </span> */}
                      {/* <i className="text-hulkindigoblue">/patterns&gt;
                                            </i> */}
                      <i className="text-hulkindigoblue">Cms APPLICATION</i>
                    </NavLink>
                  </div>
                  <p className="text-sm-left">
                    Our web based CMS solutions will allow you to alter the
                    content on your website whenever you require, so that you
                    can have complete control of your own site and its design.
                    You can add, edit and delete pages even if you have no HTML
                    skills, allowing you to keep your site fresh and up to date
                    at all times.
                  </p>
                </div>
                <div className="card-footer border-top-0 bg-white p-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        className="text-hulkindigoblue text-16 font-weight-bold"
                      >
                        Get Started
                      </NavLink>
                      <p className="mb-0 text-12"></p>
                    </div>
                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="patterns"
                      className="h1 mb-0 arrow-icon"
                    >
                      <i className="fa-solid fa-arrow-right-long text-hulkindigoblue"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex equal-height col-12 col-sm-6 col-lg-4 my-3">
              <div className="card hover-card border-0 shadow p-30">
                <div className="card-body p-0">
                  <div className="d-flex align-items-center align-items-sm-baseline flex-column mb-3">
                    <span className="bg-lightskyblue topapps-iconbox mb-4">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        aria-label="partners"
                        className="d-block"
                      >
                        <img
                          src="https://cdn.shopify.com/s/files/1/1564/7647/files/ic_hulk_partners.svg?v=1615464056"
                          alt="Ogiltz Software - partners"
                          className="img-fluid"
                        />
                      </NavLink>
                    </span>
                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="partners"
                      className="h3 mb-0"
                    >
                      {/* <span className="text-black">Oglitz
                                            </span> */}
                      {/* <i className="text-hulkskyblue">/partners&gt;
                                            </i> */}
                      <i className="text-hulkskyblue">ios APPLICATION</i>
                    </NavLink>
                  </div>
                  <p className="text-sm-left">
                    We build futuristic iOS app to enable you to reach your
                    goals.
                  </p>
                </div>
                <div className="card-footer border-top-0 bg-white p-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2">
                      <NavLink
                        to="/start-project"
                        target="_top"
                        className="text-hulkskyblue text-16 font-weight-bold"
                      >
                        Get Started
                      </NavLink>
                      <p className="mb-0 text-12"></p>
                    </div>
                    <NavLink
                      to="/start-project"
                      target="_top"
                      aria-label="partners"
                      className="h1 mb-0 arrow-icon"
                    >
                      <i className="fa-solid fa-arrow-right-long text-hulkskyblue"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Company />
    </div>
  );
};

export default Card;
