import React from "react";

function Ffooter() {
  return (
    <div>
      <div class="container">
        <footer class="py-3 my-4">
          {/* <ul class="nav justify-content-center border-bottom pb-3 mb-3">
                        <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">Home</a></li>
                        <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">Project</a></li>
                        <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">Services</a></li>
                        <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">About</a></li>
                        <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">OurTeam</a></li>
                    </ul> */}
          <p class="text-center text-muted">
            &copy; Copyright 2021 - {new Date().getFullYear()} Oglitz Software
            Pvt Ltd. All Rights Reserved.
          </p>
        </footer>
      </div>
    </div>
  );
}

export default Ffooter;
