import React from 'react';
import "./Project.css";

import projectImg1 from "../img/homelogo.PNG";
import projectImg2 from "../img/project2.PNG";
import projectImg3 from "../img/project3.PNG";
import projectImg4 from "../img/project4.PNG";
import projectImg5 from "../img/project5.PNG";
import projectImg6 from "../img/project6.PNG";
import projectImg7 from "../img/project7.PNG";
import SEO from '../SEO';

const schemaProjects = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Our Projects",
    "description": "Showcasing our projects with a range of web and app development work. Discover innovative solutions and creative implementations from our team.",
    "url": "https://www.oglitz.com/projects",
    "image": [
      projectImg1,
      projectImg2,
      projectImg3,
      projectImg4,
      projectImg5,
      projectImg6,
      projectImg7
    ].map(img => ({
      "@type": "ImageObject",
      "url": img,
      "width": 800,
      "height": 600
    })),
    "mainEntity": {
      "@type": "Collection",
      "name": "Projects",
      "description": "A collection of projects showcasing our web and app development capabilities.",
      "hasPart": [
        {
          "@type": "CreativeWork",
          "name": "PippyShare",
          "description": "A website where users can download and upload HD photos, categorized for easy access.",
          "url": "https://pippyshare.netlify.app/",
          "image": projectImg1
        },
        {
          "@type": "CreativeWork",
          "name": "Search Engine (Barbin)",
          "description": "An optimized and secure search engine for finding various information.",
          "url": "http://barbin.netlify.app/",
          "image": projectImg2
        },
        {
          "@type": "CreativeWork",
          "name": "E-Commerce Website",
          "description": "A demo e-commerce website model showcasing a range of online shopping features.",
          "url": "https://mbshop.netlify.app/",
          "image": projectImg7
        },
        {
          "@type": "CreativeWork",
          "name": "Social Media Website (ManiSocial)",
          "description": "A prototype social media platform for preview purposes, available for customization.",
          "url": "https://manisocial.netlify.app/",
          "image": projectImg3
        },
        {
          "@type": "CreativeWork",
          "name": "Custom Resume Maker",
          "description": "A tool for creating custom resumes with multiple templates and drag-and-drop functionality.",
          "url": "https://mycvmaker.netlify.app/",
          "image": projectImg4
        },
        {
          "@type": "CreativeWork",
          "name": "Coffee Shop Website (Coffee Time)",
          "description": "A prototype coffee shop website, fully mobile and desktop-friendly.",
          "url": "https://mbcoffeetime.netlify.app/",
          "image": projectImg5
        },
        {
          "@type": "CreativeWork",
          "name": "Book Store E-Commerce Website",
          "description": "An online book store with e-commerce features and a customizable theme.",
          "url": "https://onelinebook.netlify.app/",
          "image": projectImg6
        }
      ]
    }
  };

function Project() {
    return (
        <div className="project">

            {/* <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <a href="/home">Home</a>
                </li>
                <li className="breadcrumb-item active">
                    <a href="/contact">Service</a>
                </li>
            </ol>
            <hr /> */}

            <SEO
                title="Our Projects | Oglitz Software Pvt Ltd."
                description="Explore the projects completed by Oglitz Software Pvt Ltd. from innovative web solutions to creative app developments. Learn more about our work and capabilities."
                image={projectImg1} // Use a representative image for the projects page
                url="https://www.oglitz.com/project"
                keywords="Oglitz Software, web development, app development, projects, technology solutions"
                author="Oglitz Software Pvt Ltd."
                schemaData={schemaProjects}
            />

            <div className="container">

                <div className="LeaderHead">
                    <div className="container">
                        <div className="TitleBox">
                            <h2>Our <span>Projects</span></h2>
                            <p>Our AGILE approach provides not only effective solutions, but we also provide creative answers and unparalleled services to any technological challenges faced by our clients. Our Team is rich with industry and technology experts.</p>
                        </div>
                    </div>
                </div>


                <div class="row row-cols-1 row-cols-md-3 g-4">

                    <div class="col">
                        <div class="card">
                            <img src={projectImg1} class="card-img-top" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title">PippyShare</h5>
                                <p class="card-text">In this Website, you can download hd photos and also you can upload by your id. In this you can find Photo by category. This Website is fully friendly with phone as well</p>

                                <a href="https://pippyshare.netlify.app/" target="_blank" className="WebLink">Live Preview </a>
                            </div>

                        </div>
                    </div>

                    <div class="col">
                        <div class="card">
                            <img src={projectImg2} class="card-img-top" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title">Search Engine(Barbin)</h5>
                                <p class="card-text">This is a search engine. It is fully optimise and secure. You can find any thing here like google search engine </p>
                                <a href="http://barbin.netlify.app/" target="_blank" className="WebLink">Live Preview </a>
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <div class="card">
                            <img src={projectImg7} class="card-img-top" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title">E-Commerce Website</h5>
                                <p class="card-text">This is a E-Commerce website model In this backend temprarily stop by us. It is just demo Purpose. You check every thing are present In this site</p>
                                <a href="https://mbshop.netlify.app/" target="_blank" className="WebLink">Live Preview </a>
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <div class="card">
                            <img src={projectImg3} class="card-img-top" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title">Social Media Website(ManiSocial)</h5>
                                <p class="card-text">This is a Social media platform Prototype. In this backend are not implented yet. It is just preview Perpose you can order for your own logic and custmization.</p>
                                <a href="https://manisocial.netlify.app/" target="_blank" className="WebLink">Live Preview </a>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card">
                            <img src={projectImg4} class="card-img-top" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title">Custom Resume Maker</h5>
                                <p class="card-text">In this website you can make your own custom resume in very easy way by multiple template. you can drag your element in this maker</p>
                                <a href="https://mycvmaker.netlify.app/" target="_blank" className="WebLink">Live Preview </a>
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <div class="card">
                            <img src={projectImg5} class="card-img-top" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title">Coffee Shop Website (Coffee Time)</h5>
                                <p class="card-text">This is a coffee Website prototype. You can check it and order it for your need and logic. It is fully Mobile and desktop friendly</p>
                                <a href="https://mbcoffeetime.netlify.app/" target="_blank" className="WebLink">Live Preview </a>
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <div class="card">
                            <img src={projectImg6} class="card-img-top" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title">Book Store E-Commerce Website</h5>
                                <p class="card-text">This is a online book store e-commerce website. In this your can show your book item and add to cart and all e-com feature.You can change your theme in on click</p>
                                <a href="https://onelinebook.netlify.app/" target="_blank" className="WebLink ">Live Preview </a>
                            </div>
                        </div>
                    </div>

                </div>

                {/* <div className="row row-cols-1 row-cols-md-3 g-4 ggg">

                    <div class="card text-white bg-primary mb-3 col" >

                        <div class="card-header">Header</div>
                        <div class="card-body">
                            <h5 class="card-title">Primary card title</h5>
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        </div>

                    </div>
                    <div class="card text-white bg-secondary mb-3 col" >
                        <div class="card-header">Header</div>
                        <div class="card-body">
                            <h5 class="card-title">Secondary card title</h5>
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        </div>
                    </div>
                    <div class="card text-white bg-success mb-3 col" >
                        <div class="card-header">Header</div>
                        <div class="card-body">
                            <h5 class="card-title">Success card title</h5>
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        </div>
                    </div>
                    <div class="card text-white bg-danger mb-3 col" >
                        <div class="card-header">Header</div>
                        <div class="card-body">
                            <h5 class="card-title">Danger card title</h5>
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        </div>
                    </div>
                    <div class="card text-dark bg-warning mb-3 col" >
                        <div class="card-header">Header</div>
                        <div class="card-body">
                            <h5 class="card-title">Warning card title</h5>
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        </div>
                    </div>
                    <div class="card text-dark bg-info mb-3 col" >
                        <div class="card-header">Header</div>
                        <div class="card-body">
                            <h5 class="card-title">Info card title</h5>
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        </div>
                    </div>
                    <div class="card text-dark bg-light mb-3 col" >
                        <div class="card-header">Header</div>
                        <div class="card-body">
                            <h5 class="card-title">Light card title</h5>
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        </div>
                    </div>
                    <div class="card text-white bg-dark mb-3 col" >
                        <div class="card-header">Header</div>
                        <div class="card-body">
                            <h5 class="card-title">Dark card title</h5>
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        </div>
                    </div>

                </div>
 */}


            </div>







        </div>
    )
}

export default Project;
