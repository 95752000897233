import React from 'react';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import './NewFooter.css'; // Import your CSS file for styling
import logo from "../img/oglitzWithe.png";
import { Helmet } from 'react-helmet';

const schemaData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Oglitz Software Pvt Ltd.",
    "url": "https://www.oglitz.com",
    "logo": logo,
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "811/78 Yadav Nagar",
      "addressLocality": "Ahirwan, Katrisafipur",
      "addressRegion": "Kanpur Nagar",
      "addressCountry": "India",
      "postalCode": "208007"
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+91 95321 19007",
      "contactType": "customer service",
      "email": "info@oglitz.com"
    },
    "sameAs": [
      "https://www.facebook.com/profile.php?id=61565024655930",
      "https://www.linkedin.com/company/oglitz/",
      "https://x.com/OglitzSoftware",
      "https://wa.me/919532119007",
      "https://www.instagram.com/oglitzsoftware"
    ]
  };

const NewFooter = () => {
    return (
        <footer className="uiq-footer">
             <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(schemaData)}
                </script>
            </Helmet>
            <div className="uiq-footer-content">
                <div className="uiq-footer-section uiq-footer-logo-desc">
                    <img src={logo} alt="Oglitz Software Pvt Ltd. Logo" className="uiq-footer-logo" />
                    <p className="uiq-footer-desc">
                        We offer global custom software development in web and mobile apps, digital marketing, and eCommerce, delivering high-quality solutions at competitive prices.
                    </p>
                </div>
                <div className="uiq-footer-section uiq-footer-menu">
                    <h4 className="uiq-footer-section-title">Menu</h4>
                    <ul className="uiq-footer-menu-list">
                        <li>
                            <a
                                href="/"
                                className="footer-navItem"
                                title="Home - Oglitz Software Pvt Ltd. Official Website"
                            >
                                Home
                            </a>
                        </li>
                        <li>
                            <a
                                href="/about"
                                className="footer-navItem"
                                title="About Us - Learn More About Oglitz Software Pvt Ltd."
                            >
                                About Us
                            </a>
                        </li>
                        <li>
                            <a
                                href="/contact"
                                className="footer-navItem"
                                title="Contact Us - Get in Touch with Oglitz Software Pvt Ltd."
                            >
                                Contact
                            </a>
                        </li>
                        <li>
                            <a
                                href="/project"
                                className="footer-navItem"
                                title="Project - Explore Our Projects and Case Studies"
                            >
                                Project
                            </a>
                        </li>
                        <li>
                            <a
                                href="/design"
                                className="footer-navItem"
                                title="Design Pack - Access Our Design Pack and Resources"
                            >
                                Design Pack
                            </a>
                        </li>
                    </ul>
                </div>

                <div className="uiq-footer-section uiq-footer-services">
                    <h4 className="uiq-footer-section-title">Services</h4>
                    <ul className="uiq-footer-services-list">
                        <li>
                            <a
                                href="/start-project"
                                title="Cloud Services - Expert Cloud Solutions and Consulting"
                            >
                                Cloud Services
                            </a>
                        </li>
                        <li>
                            <a
                                href="/start-project"
                                title="Digital Marketing - Comprehensive Digital Marketing Services"
                            >
                                Digital Marketing
                            </a>
                        </li>
                        <li>
                            <a
                                href="/start-project"
                                title="Design & UI/UX - Professional Design and UI/UX Services"
                            >
                                Design & UI/UX
                            </a>
                        </li>
                        <li>
                            <a
                                href="/start-project"
                                title="E-Commerce Solutions - Tailored E-Commerce Development"
                            >
                                E-Commerce Solutions
                            </a>
                        </li>
                        <li>
                            <a
                                href="/start-project"
                                title="React JS Applications - Development of React JS Apps"
                            >
                                React JS Applications
                            </a>
                        </li>
                        <li>
                            <a
                                href="/start-project"
                                title="Application Maintenance - Ongoing Support and Maintenance"
                            >
                                Application Maintenance
                            </a>
                        </li>
                        <li>
                            <a
                                href="/start-project"
                                title="Search Engine Optimization (SEO) - Improve Your Online Visibility"
                            >
                                Search Engine Optimization (SEO)
                            </a>
                        </li>
                        <li>
                            <a
                                href="/start-project"
                                title="Android App Development - Custom Android Application Development"
                            >
                                Android App Development
                            </a>
                        </li>
                        <li>
                            <a
                                href="/start-project"
                                title="Website Designing - Creative and Responsive Website Design"
                            >
                                Website Designing
                            </a>
                        </li>
                        <li>
                            <a
                                href="/start-project"
                                title="React Native Applications - Cross-Platform App Development with React Native"
                            >
                                React Native Applications
                            </a>
                        </li>
                        <li>
                            <a
                                href="/start-project"
                                title="Website Redesigning - Revamp Your Existing Website"
                            >
                                Website Redesigning
                            </a>
                        </li>
                        <li>
                            <a
                                href="/start-project"
                                title="CMS Applications - Content Management System Solutions"
                            >
                                CMS Applications
                            </a>
                        </li>
                        <li>
                            <a
                                href="/start-project"
                                title="iOS Applications - Custom iOS App Development"
                            >
                                iOS Applications
                            </a>
                        </li>
                        <li>
                            <a
                                href="/start-project"
                                title="Custom Software Development - Tailored Software Solutions"
                            >
                                Custom Software Development
                            </a>
                        </li>
                        <li>
                            <a
                                href="/start-project"
                                title="API Development - Build and Integrate APIs"
                            >
                                API Development
                            </a>
                        </li>
                        <li>
                            <a
                                href="/start-project"
                                title="More Services - Explore Additional Services We Offer"
                            >
                                More Services
                            </a>
                        </li>
                    </ul>
                </div>

                <div className="uiq-footer-section uiq-footer-technologies">
                    <h4 className="uiq-footer-section-title">Technologies</h4>
                    <ul className="uiq-footer-technologies-list">
                        <li title="Microsoft Technologies - Solutions Using Microsoft Tools and Platforms">Microsoft Technologies</li>
                        <li title="Android & iOS - Mobile Application Development for Android and iOS">Android & iOS</li>
                        <li title="PHP Core & Frameworks - PHP Development Services">PHP Core & Frameworks</li>
                        <li title="Java Platform - Java-based Solutions and Development">Java Platform</li>
                        <li title="Python - Python Programming and Development">Python</li>
                        <li title="Native App - Native Application Development Services">Native App</li>
                        <li title="AI/Machine Learning - Artificial Intelligence and Machine Learning Solutions">AI/Machine Learning</li>
                        <li title="React JS - React JS Development Services">React JS</li>
                        <li title="React Native - Cross-Platform App Development with React Native">React Native</li>
                        <li title="Flutter - Mobile Development with Flutter">Flutter</li>
                        <li title="Node.js - Server-Side Development with Node.js">Node.js</li>
                        <li title="MongoDB - NoSQL Database Solutions with MongoDB">MongoDB</li>
                        <li title="MySQL - Relational Database Solutions with MySQL">MySQL</li>
                        <li title="GraphQL - Query Language for APIs with GraphQL">GraphQL</li>
                        <li title="Docker - Containerization and Docker Solutions">Docker</li>
                        <li title="Cloud Platforms (AWS, Azure, Google Cloud) - Cloud Solutions on Major Platforms">Cloud Platforms (AWS, Azure, Google Cloud)</li>
                        <li title="More Technologies - Explore Additional Technologies We Use">
                            {/* <a
                                href="/start-project"
                                title="More Technologies - Explore Additional Technologies We Use"
                            > */}
                            More Technologies
                            {/* </a> */}
                        </li>
                    </ul>
                </div>

                <div className="uiq-footer-section uiq-footer-social-media">
                    <h4 className="uiq-footer-section-title">Follow Us</h4>
                    <a
                        href="https://www.facebook.com/profile.php?id=61565024655930"
                        className="uiq-social-icon"
                        aria-label="Facebook"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Follow us on Facebook - Oglitz Software Pvt Ltd."
                    >
                        <FaFacebookF />
                    </a>
                    <a
                        href="https://www.linkedin.com/company/oglitz/"
                        className="uiq-social-icon"
                        aria-label="LinkedIn"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Connect with us on LinkedIn - Oglitz Software Pvt Ltd."
                    >
                        <FaLinkedinIn />
                    </a>
                    <a
                        href="https://x.com/OglitzSoftware"
                        className="uiq-social-icon"
                        aria-label="Twitter"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Follow us on Twitter - Oglitz Software Pvt Ltd."
                    >
                        <FaTwitter />
                    </a>
                    <a
                        href="https://wa.me/919532119007"
                        className="uiq-social-icon"
                        aria-label="WhatsApp"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Chat with us on WhatsApp - Oglitz Software Pvt Ltd."
                    >
                        <FaWhatsapp />
                    </a>
                    <a
                        href="https://www.instagram.com/oglitzsoftware"
                        className="uiq-social-icon"
                        aria-label="Instagram"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Follow us on Instagram - Oglitz Software Pvt Ltd."
                    >
                        <FaInstagram />
                    </a>
                    {/* <p className="">Address : 811/78 yadav nagar ahirwan, Katrisafipur, Kanpur Nagar, Uttar Pradesh - 208007</p>
                    <div className="uiq-footer-contact">
                        <p>Send us your requirements at  <br />
                            Email: <a href="mailto:info@oglitz.com">info@oglitz.com</a>
                            <br /> Mobile Number:  +91 95321 19007</p>
                    </div> */}
                    <address>
                        <p className="uiq-footer-address">
                            <strong>Address: </strong>
                            811/78 Yadav Nagar, Ahirwan, Katrisafipur, Kanpur Nagar, Uttar Pradesh - 208007, India
                        </p>
                    </address>
                    <div className="uiq-footer-contact">
                        <p>
                            <strong>Send us your requirements:</strong><br />
                            <strong>Email: </strong>
                            <a href="mailto:info@oglitz.com" className="uiq-footer-email">
                               info@oglitz.com
                            </a><br />
                            <strong>Mobile Number: </strong>
                            <a href="tel:+919532119007" className="uiq-footer-phone">
                                +91 95321 19007
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="uiq-footer-bottom">
                <p>&copy; {new Date().getFullYear()} Oglitz Software Pvt Ltd. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default NewFooter;
