import React from 'react';
import service from "../img/ser.png"
import "./Service.css";
import testImg from "../img/imgg.jpg"
import testImg2 from "../img/imga.png";




function Service() {

    
      

    return (
        <div className="service">
            <div className="s-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 contact-line">
                            <div className="ban-head-text">
                                <h1>Leveraging Your Business Performance with Adaptive & Scalable Web Applications </h1>
                                <p>Developing an online business is the watchword in the current and evolving business world. The success here purely lies in 'Passion', 'Innovation', and 'Persuasion'. And that's what we at Oglitz do, develop web applications that are innovative, dynamic, secure, scalable, and adaptive. </p>

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="ban-head-img">
                                <img src={service} className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">
                    <a href="/contact">Service</a>
                </li>
            </ol> */}


            <div class="services pt-md-5 pb-md-5 bg-white">
                <div class="container">
                    <div class="row">
                        <div class="ser-head">
                            <h2>Service</h2>

                            <p>"Information technology and business are becoming inextricably interwoven. I don't think anybody can talk meaningfully about one without the talking about the other" </p>
                            <p>The quote by Bill Gates clearly signifies the growth and evolution of IT in all the spheres of life. The industry envisioned to grow to US$ 350 billion by 2025 is taking a quantum leap visualizing a paradigm shift in the economic and social architecture at the global level. </p>
                            <p>To be more precise, the world economy is undergoing a breakthrough revolution with the momentous introduction and rapid evolution of IT (Information Technology). And there stands Oglitz, as the name to reckon with when it comes to reliable and scalable IT service providers.  </p>
                            <p>Whether you talk about hardware challenges, software solutions, internet issues, or any sort of core business operations, we have a trusted solution to all. We take pride in our obsessively passionate team of IT professionals who simplify business workflows with innovations meeting perfection at all bends and turns of your business journey. </p>
                        </div>
                    </div>

                    <div class="row pt-3 pt-md-5">
                        <div class="ser-head">
                            <h2>Explore our scale of Services</h2>

                            <p>"Together we can create a benchmark" is the belief of our foundation. You lay your focus on core business operations while we empower the utilization of technologies and advancements to bring you a competitive edge with our comprehensive range of IT services. </p>
                        </div>
                    </div>

                    <div class="row row-cols-1 row-cols-md-3 g-4 cardModelBox">

                        <div class="col shadow-make">
                            <div class="card">
                                <img src={testImg} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Card title</h5>
                                    <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <a href="#" class="btn btn-light">Go somewhere</a>
                                    </div>

                                   

                                </div>
                            </div>
                        </div>

                        <div class="col shadow-make">
                            <div class="card">
                                <img src={testImg2} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Card title</h5>
                                    <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <a href="#" class="btn btn-light">Go somewhere</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col shadow-make">
                            <div class="card">
                                <img src={testImg} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Card title</h5>
                                    <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content.</p>
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <a href="#" class="btn btn-light">Go somewhere</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col shadow-make">
                            <div class="card">
                                <img src={testImg2} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Card title</h5>
                                    <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>

                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <a href="#" class="btn btn-light">Go somewhere</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col shadow-make">
                            <div class="card">
                                <img src={testImg} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Card title</h5>
                                    <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <a href="#" class="btn btn-light">Go somewhere</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col shadow-make">
                            <div class="card">
                                <img src={testImg2} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Card title</h5>
                                    <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <a href="#" class="btn btn-light">Go somewhere</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col shadow-make">
                            <div class="card">
                                <img src={testImg} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Card title</h5>
                                    <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content.</p>
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <a href="#" class="btn btn-light">Go somewhere</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col shadow-make">
                            <div class="card">
                                <img src={testImg2} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Card title</h5>
                                    <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <a href="#" class="btn btn-light">Go somewhere</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>


        </div>
    )
}

export default Service;