import About from "./About/About";
import React from "react";
import "./App.css";
import Card from "./Card/Card";

import Footers from "./Footers/Footers";
import Home from "./Home/Home";
import Navbar from "./NavBar/Navbar";
import OurTeam from "./OurTeam/OurTeam";
import Project from "./Project/Project";
import Service from "./Service/Service";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import ScrollToTop from "react-scroll-to-top";
import Ffooter from "./Ffooter/Ffooter";
import ContactFull from "./ContactFull/ContactFull";
import ContactForm from "./ContactForm/ContactForm";
import LoadingBar from "react-top-loading-bar";
import { useState } from "react";
import DetailedFooter from "./DetailedFooter/DetailedFooter";
import ViewDetails from "./ViewDetails/ViewDetails";
import PageNotFound from "./PageNotFound";
import CompanyInfo from "./CompanyInfo";
import StartProject from "./StartProject/StartProject";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DesignPage from "./DesignPackage/DesignPage";
import DemoPage from "./DesignPackage/DemoPage";
import NewFooter from "./NewFooter/NewFooter";
import AddDesignPackageForm from "./DesignPackage/AddDesignPackageForm";

const AppContent = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const visitSoftwareHub = queryParams.get("visit-software-hub");

  // Log the query parameters for debugging
  console.log("Query Params:", queryParams.toString());
  console.log("Visit Software Hub:", visitSoftwareHub);

  // Render CompanyInfo if the parameter is present, regardless of its value
  if (visitSoftwareHub !== null) {
    return <CompanyInfo />;
  } else {
    return <Home />;
  }
};

function App() {
  const [progress, setProgress] = useState(0);

  return (
    <div className="App">
      <ScrollToTop smooth top="30" color="#f18613" />
      <Router>
        <LoadingBar
          color="#f11946"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
        <Navbar />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" element={<AppContent />} />
          <Route path="/about" element={<About />} />
          {/* <Route path="/getStarted" element={<ViewDetails />} /> */}
          <Route path="/contact" element={<ContactFull />} />
          {/* <Route path="/service" element={<Service />} /> */}
          {/* <Route path="/ourTeam" element={<OurTeam />} /> */}
          <Route path="project" element={<Project />} />
          <Route path="visit-software-hub" element={<CompanyInfo />} />
          <Route path="start-project" element={<StartProject />} />
          <Route path="design" element={<DesignPage />} />
          {/* <Route path="add-design" element={<AddDesignPackageForm />} /> */}
          <Route path="design/:id/:packagename" element={<DemoPage />} />
          {/* Catch-all route for 404 Page Not Found */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        {/* <Footers /> */}
        {/* <Ffooter /> */}
        <NewFooter/>
        {/* <DetailedFooter /> */}
      </Router>
    </div>
  );
}

export default App;


// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBw8VlMhcYuaESDeZ20ZRrAjXUWuNG3xEE",
//   authDomain: "oglitz-software.firebaseapp.com",
//   projectId: "oglitz-software",
//   storageBucket: "oglitz-software.appspot.com",
//   messagingSenderId: "875317218028",
//   appId: "1:875317218028:web:70318ffee89338db90b3c9",
//   measurementId: "G-6P821JZN4B"
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);