import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Container, Header, Image, Segment, Grid, Table } from 'semantic-ui-react';
import { FaFacebookF, FaLinkedinIn, FaShareAlt, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { MdArrowBackIosNew } from 'react-icons/md';
import CustomModal from './Modal';
import { db } from '../firebase'; // Ensure you import your Firebase config
import { doc, getDoc } from 'firebase/firestore';
import SEO from '../SEO';
import "./DemoPage.css"

const DemoPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [packageData, setPackageData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const baseUrl = "https://www.oglitz.com";

    useEffect(() => {
        const fetchPackageData = async () => {
            try {
                const docRef = doc(db, 'designPackages', id);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setPackageData(docSnap.data());
                } else {
                    setError('No such document!');
                }
            } catch (err) {
                setError('Error fetching data.');
                console.error('Error fetching document:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchPackageData();
    }, [id]);

    const openRequestModal = (data) => {
        setModalData(data);
        setModalShow(true);
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;
    if (!packageData) return <div>No package data found!</div>;

    // Share URL
    const shareUrl = `${baseUrl}${packageData.link}` // The current URL

    // Schema Data for structured data
    const schemaPackages = {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": packageData.title,
        "description": packageData.description,
        "benefits": packageData.benefits,
        "moreInfo": packageData.moreInfo,
        "keyFeatures": packageData.keyFeatures,
        "image": packageData.logo[0],
        "offers": {
            "@type": "Offer",
            "priceCurrency": "INR",
            "price": packageData.price,
            "url": `${baseUrl}${packageData.link}`,
            "itemCondition": "https://schema.org/NewCondition",
            "availability": "https://schema.org/InStock"
        },
        "includedInPackage": packageData.whatsIncluded.map(item => ({
            "@type": "PropertyValue",
            "name": item.feature,
            "value": item.detail
        })),
        "demoImages": packageData.logo.map(url => ({
            "@type": "ImageObject",
            "url": url
        }))
    };

    // Dynamic title with packageData.title
    const seoTitle = packageData.title
        ? `${packageData.title} | Oglitz Software Pvt Ltd.`
        : 'Design Packages | Oglitz Software Pvt Ltd.';

    return (
        <Container className='demo-page-container'>
            <SEO
                title={seoTitle}
                description={`Discover the premium design packages offered by Oglitz Software Pvt Ltd., including logo design, branding, and social media assets. Our packages are tailored to meet the needs of businesses looking to enhance their brand identity. Request a quote today to get started!`}
                image={packageData.logo[0]} // Update with your actual image URL
                url={`${baseUrl}${packageData.link}`}
                keywords="design packages, logo design, branding, social media design, graphic design, business branding, professional design services, Oglitz Software Pvt Ltd., Oglitz Software"
                author="Oglitz Software Pvt Ltd."
                twitterHandle="@oglitz"
                schemaData={schemaPackages}
            />

            <Segment className='header-segment' padded='very'>
                <Grid>
                    <Grid.Row>
                        <Grid.Column className='headingStyleDemo' width={12}>
                            <div
                                className='backIconStyleDemo'
                                onClick={() => navigate(-1)} // Go back to the previous page
                            >
                                <MdArrowBackIosNew /> Back
                            </div>
                            <Header as='h1' className='title-header'>{packageData.title}</Header>
                        </Grid.Column>
                        <Grid.Column width={4} className='request-button-column'>
                            <div className='share-buttonStyleBox'>
                                <Button
                                    className='share-button'
                                    icon
                                    onClick={() => document.getElementById('share-options').classList.toggle('visible')}
                                >
                                    Share <FaShareAlt />
                                </Button>
                                <div id='share-options' className='share-options'>
                                    <FacebookShareButton url={shareUrl} className='share-icon'>
                                        <FaFacebookF />
                                    </FacebookShareButton>
                                    <LinkedinShareButton url={shareUrl} className='share-icon'>
                                        <FaLinkedinIn />
                                    </LinkedinShareButton>
                                    <TwitterShareButton url={shareUrl} className='share-icon'>
                                        <FaTwitter />
                                    </TwitterShareButton>
                                    <WhatsappShareButton url={shareUrl} className='share-icon'>
                                        <FaWhatsapp />
                                    </WhatsappShareButton>
                                </div>
                            </div>
                            <Button
                                className='deomunique-card-button'
                                primary
                                onClick={() => openRequestModal(packageData)}
                            >
                                Request Order
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Segment className='details-segment'>
                <Segment className='image-segment'>
                    <Grid stackable columns={3} className='DemoImagesStyle'>
                        {packageData.logo && packageData.logo.map((url, index) => (
                            <Grid.Column key={index}>
                                <Image src={url} alt={`Logo ${index + 1}`} size='small' className='package-image' />
                            </Grid.Column>
                        ))}
                    </Grid>
                </Segment>

                <p style={{whiteSpace:"pre-line"}}><strong>Price:</strong> ₹{packageData.price}</p>
                <p style={{whiteSpace:"pre-line"}}><strong>Duration:</strong> {packageData.duration}</p>
                <p style={{whiteSpace:"pre-line"}}><strong>Description:</strong> {packageData.description}</p>
                <p style={{whiteSpace:"pre-line"}}><strong>Benefits:</strong> {packageData.benefits}</p>
                <p style={{whiteSpace:"pre-line"}}><strong>More Info:</strong> {packageData.moreInfo}</p>
                <p style={{whiteSpace:"pre-line"}}><strong>Key Features:</strong> {packageData.keyFeatures}</p>

                <Segment className='whats-included-segment'>
                    <Header as='h3' className='whats-included-segmentHeading'>What's Included:</Header>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Feature</Table.HeaderCell>
                                <Table.HeaderCell>Detail</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {packageData.whatsIncluded.map((item, index) => (
                                <Table.Row key={index}>
                                    <Table.Cell>{item.feature}</Table.Cell>
                                    <Table.Cell>{item.detail}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Segment>
            </Segment>
            <CustomModal
                isOpen={modalShow}
                onRequestClose={() => setModalShow(false)}
                modalData={modalData}
            />
        </Container>
    );
};

export default DemoPage;
