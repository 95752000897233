import React from 'react';
import "./Footer.css";

function Footers() {
    return (
        <div className="footers">

            <div class="container">

                <footer class="py-5">

                    <div class="row">

                        <div class="col-2">
                            <h5>Services</h5>
                            <ul class="nav flex-column">
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Web Application Development </a></li>
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Product Development & Maintenance</a></li>
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Mobile Application Development</a></li>
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Website Designing</a></li>
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">SOFTWARE TESTING</a></li>
                            </ul>
                        </div>

                        <div class="col-2">

                            <h5>Technologies</h5>
                            <ul class="nav flex-column">
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Microsoft Technologies</a></li>
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Andorid & iOS</a></li>
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">PHP Core & Frameworks </a></li>
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">AI/Machine Learning</a></li>
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Native App</a></li>
                            </ul>
                        </div>

                        <div class="col-2">
                            <h5>Industries </h5>
                            <ul class="nav flex-column">
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Education</a></li>
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Energy & Utilities</a></li>
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Pricing</a></li>
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Media & Entertainment</a></li>
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Transportation & Logistics</a></li>
                            </ul>
                        </div>

                        <div class="col-4 offset-1">
                            <form>
                                <h5>Subscribe to our newsletter</h5>
                                <p>Monthly digest of whats new and exciting from us.</p>
                                <div class="d-flex w-100 gap-2">
                                    <label for="newsletter1" class="visually-hidden">Email address</label>
                                    <input id="newsletter1" type="text" class="form-control" placeholder="Email address" />
                                    <button class="btn btn-primary" type="button">Subscribe</button>
                                </div>
                            </form>

                        </div>

                    </div>

                    <div class="d-flex justify-content-between py-4 my-4 border-top">
                        <p>&copy; Copyright 2022 Oglitz Software Pvt. Ltd . All Rights Reserved. </p>
                        <ul class="list-unstyled d-flex">
                            <i className="fa-brands fa-instagram ms-3 smLink"></i>
                            <i className="fa-brands fa-twitter ms-3 smLink"></i>
                            <i className="fa-brands fa-linkedin ms-3 smLink"></i>
                        </ul>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default Footers