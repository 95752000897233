import React from "react";
import Card from "../Card/Card";
import "./Home.css";
import pippyshare from "../img/pippyshare.png";
import OglitzTechBlogIcon from "../img/Oglitz-tech-blog-icon.png";
import barbin from "../img/barbin.png";
import homelogo2 from "../img/heroLogo1.png";
// import homelogo2 from "../img/homelogo2.jpeg";
// import images from '../img/homelogo.PNG'
import bglogo from "../img/bglogo.png";
import { Link, NavLink } from "react-router-dom";
import SEO from "../SEO";
import logo from "../img/Oglitz-main-icon.png";


const Home = () => {
  const divStyle = {
    // backgroundColor: 'blue',
    width: "600px",
    height: "600px",
    position: "absolute",
    left: 0,
    top: 0,
  };

  return (
    <div className="home bg-home">
      {/* <img src={bglogo} alt="" className="img-bg-logo" /> */}
      <SEO
        title="Oglitz Software Pvt Ltd - Leading Custom Software Development Company"
        description="Oglitz Software Pvt Ltd offers comprehensive custom software development services globally, specializing in web applications, mobile app development, digital marketing, and eCommerce solutions to meet your business needs at competitive prices."
        image={logo}
        url="https://www.oglitz.com/"
        keywords="Oglitz, Oglitz Software, Oglitz Software Pvt Ltd, Software Development, Custom Software Development, Web Application Development, Mobile App Development, Digital Marketing, eCommerce Solutions, IT Services, Software Company, Kanpur, Global Software Services"
        author="Oglitz Software Pvt Ltd"
      />
      <div className="left">
        <div className="col-12  order-2 order-lg-1  text-center text-lg-left">
          {/* <h1 className="display-4 font-weight-black text-gray-medium mt-2 mb-3 mt-md-3 mb-md-4">
            Oglitz Software Provide Better Experience
          </h1> */}
          <h1 className="display-4 font-weight-black text-gray-medium mt-2 mb-3 mt-md-3 mb-md-4">
            <span style={{ color: "#e07b12" }}>Oglitz</span>{" "}
            <span style={{ color: "#18665c" }}>Software</span> Provide Better
            Experience
          </h1>

          <p className="text-20 mb-4">
            Fully personalized ecommerce experiences with native apps,{" "}
            <span> web design</span> and web development services.
          </p>
          <div className="d-flex flex-wrap align-items-center justify-content-around justify-content-md-center justify-content-lg-start mb-4 mb-md-5">
            <div className="mr-md-3">
              <NavLink
                to="/start-project"
                className="btn btn-primary icon-btn mb-3 mb-sm-0 mb-lg-3 mb-xl-0 projectBtn"
              >
                Start a Project
                <i className="fa-solid fa-arrow-right-long"></i>
              </NavLink>
            </div>
          </div>
          <div className="moreImg">
            {/* <a href="https://blog.oglitz.com/" target="_blank">
              {" "}
              <img src={OglitzTechBlogIcon} alt="" className="" />
            </a> */}
            <a href="https://pippyshare.netlify.app/" target="_blank">
              {" "}
              <img src={pippyshare} alt="" className="" />
            </a>
          </div>
          {/* <div className="moreImg"><img src={barbin} alt="" className="" /></div> */}
        </div>
      </div>
      <div className="right ">
        {/* mitDiv animation class hai ye */}
        <img src={homelogo2} alt="" />
        {/* <img src={images} alt="" /> */}
        {/* <img className="imgOver" src="https://cdn.shopify.com/s/files/1/1564/7647/files/appstore_screenshot_775x.jpg?v=1647842603" alt="" /> */}
        {/* <div className="ads-videoFor-web"><iframe src="https://player.vimeo.com/video/705691561?h=b66078ad29&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="5px" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen autoplay style={divStyle} title="Oglitz Software Provide Better Experience"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script> */}
      </div>

      {/* style={{marginRight: spacing + 'em'}} */}
      {/* style={{paddingTop: 56.25 +'%',position:relative}} */}

      <Card />
    </div>
  );
};

export default Home;
