// src/components/SEO.js
import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({
  title,
  description,
  image,
  url,
  type = "website",
  author = "Oglitz Software Pvt Ltd.",
  keywords = "",
  robots = "index, follow",
  twitterHandle = "@oglitz",
  locale = "en_US",
  canonical = url,
  fbAppId = "", // Facebook App ID if applicable
  schemaData = null // New prop for JSON-LD schema data
}) => (
  <Helmet>
    {/* Title and Meta Description */}
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords} />
    <meta name="robots" content={robots} />
    <meta name="author" content={author} />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <link rel="canonical" href={canonical} />
    {/* Open Graph Meta Tags */}
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />
    <meta property="og:url" content={url} />
    <meta property="og:type" content={type} />
    <meta property="og:site_name" content="Oglitz Software Pvt Ltd." />
    <meta property="og:locale" content={locale} />
    {fbAppId && <meta property="fb:app_id" content={fbAppId} />} {/* Optional: for Facebook Analytics */}
    {/* Twitter Card Meta Tags */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content={twitterHandle} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={image} />
    {/* Additional Meta Tags */}
    <meta property="article:published_time" content="2023-09-01T12:00:00Z" /> {/* Optional: for articles */}
    <meta property="article:modified_time" content="2023-09-01T12:00:00Z" /> {/* Optional: for articles */}
    <meta property="og:image:alt" content={description} /> {/* Alternative text for image */}
    <meta name="twitter:image:alt" content={description} /> {/* Alternative text for image */}
    <meta property="og:video" content="URL to video" /> {/* Optional: for video content */}
    <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" /> {/* Enhanced indexing */}
    
    {/* JSON-LD Schema Data */}
    {schemaData && (
      <script type="application/ld+json">
        {JSON.stringify(schemaData)}
      </script>
    )}
  </Helmet>
);

export default SEO;
