import React from 'react';
import "./OurTeam.css";
import "./Resop.css";
import atul from "../img/atul.jpeg";
import alok from "../img/alok.jpg";
import jay from "../img/jay.jpg";
import doemimg from "../img/imgg.jpg"
import doemimg2 from "../img/satyam.jpg"
import doemimg3 from "../img/imga2.png"
import ravi from "../img/ravi.jpeg";
import suraj from "../img/suraj1.jpg"
import bhai from "../img/bhai.jpeg"
import me from "../img/me.jpg"











function OurTeam() {
    return (
        <div className="ourTeam">

            {/* <ul>
                <li> <a href="#"> <i className="fa fa-home"></i> </a></li>
                <li> <a href="#"> Our Team </a> </li>
            </ul> */}

            {/* <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <a href="/home">Home</a>
                </li>
                <li className="breadcrumb-item active">
                    <a href="/contact">Service</a>
                </li>
            </ol>
            <hr /> */}

            <section>
                <div className="LeaderArea">
                    <div className="LeaderHead">
                        <div className="container">
                            <div className="TitleBox">
                                <h2>Our <span>Team</span></h2>
                                <p>Our AGILE approach provides not only effective solutions, but we also provide creative answers and unparalleled services to any technological challenges faced by our clients. Our Team is rich with industry and technology experts.</p>
                            </div>
                        </div>
                    </div>

                    <div className="TitleBox">
                        <h2>Meet the <span>Leaders</span></h2>
                    </div>

                    <div className="LeaderBody">
                        <div className="container">
                            <div className="row">

                                <div className="col-sm-6">
                                    <div className="LeaderBox">
                                        <figure>
                                            <img src={atul} alt="atul mani Founder | CEO" />
                                        </figure>
                                        <figcaption>
                                            <h4>Atul Mani Tripathi</h4>
                                            <h5>Founder | CEO</h5>
                                            <p>Atul Mani manages the entire Team at oglitz and is responsible for Sales, Growth, Strategy and Ensures Customer Success. </p>
                                            <ul>
                                                <li>
                                                    <a target="_blank" href="https://www.linkedin.com/in/atul-mani-tripathi-877b25236">
                                                        <i class="fa-brands fa-linkedin"></i>
                                                    </a>

                                                </li>
                                                <li>
                                                    <a target="_blank" href="https://www.instagram.com/er_atulmani">
                                                        <i class="fa-brands fa-instagram"></i>
                                                    </a>

                                                </li>
                                            </ul>
                                        </figcaption>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="LeaderBox">
                                        <figure>
                                            <img src={alok} />
                                        </figure>
                                        <figcaption>
                                            <h4>Alok Mani Tripathi </h4>
                                            <h5>Head of Operations & Technology </h5>
                                            <p>Alok is responsible for leading the development of the Projects and execution of Mobulous' long-term strategy including all executive management matters.</p>
                                            <ul>
                                                <li>
                                                    <a target="_blank" href="https://www.linkedin.com/in/alok-mani-tripathi">
                                                        <i class="fa-brands fa-linkedin"></i>
                                                    </a>

                                                </li>
                                                <li>
                                                    <a target="_blank" href="https://www.instagram.com/er_alokmani">
                                                        <i class="fa-brands fa-instagram"></i>
                                                    </a>

                                                </li>
                                            </ul>
                                        </figcaption>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>

                </div>
            </section>

            {/* <section>
                <div className="TeamArea">
                    <div className="container">
                        <div className="TitleBox">
                            <h2>Meet the <span>Managers</span></h2>
                        </div>

                        <div className="row">
                            <div className="col-sm-4">
                                <div className="TeamBox">
                                    <figure>
                                        <img src={doemimg} />
                                    </figure>
                                    <figcaption>
                                        <h4>Alok </h4>
                                        <p>Head : Backend | Technology </p>
                                    </figcaption>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="TeamBox">
                                    <figure>
                                        <img src={doemimg2} />
                                    </figure>
                                    <figcaption>
                                        <h4>Atul</h4>
                                        <p>Project Manager </p>
                                    </figcaption>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="TeamBox">
                                    <figure>
                                        <img src={doemimg3} />
                                    </figure>
                                    <figcaption>
                                        <h4>Alok </h4>
                                        <p>Project Manager</p>
                                    </figcaption>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="TeamBox">
                                    <figure>
                                        <img src={doemimg2} />
                                    </figure>
                                    <figcaption>
                                        <h4>Atul</h4>
                                        <p>Business Development Manager</p>
                                    </figcaption>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="TeamBox">
                                    <figure>
                                        <img src={doemimg3} />
                                    </figure>
                                    <figcaption>
                                        <h4>Alok </h4>
                                        <p>Project Manager</p>
                                    </figcaption>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="TeamBox">
                                    <figure>
                                        <img src={doemimg} />
                                    </figure>
                                    <figcaption>
                                        <h4>Atul </h4>
                                        <p>Project Manager</p>
                                    </figcaption>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="TeamBox">
                                    <figure>
                                        <img src={doemimg3} />
                                    </figure>
                                    <figcaption>
                                        <h4>Alok</h4>
                                        <p>Jr. Project Coordinator </p>
                                    </figcaption>
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <div className="TeamBox">
                                    <figure>
                                        <img src={doemimg2} />
                                    </figure>
                                    <figcaption>
                                        <h4>Atul </h4>
                                        <p>HR Manager </p>
                                    </figcaption>
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <div className="TeamBox">
                                    <figure>
                                        <img src={doemimg} />
                                    </figure>
                                    <figcaption>
                                        <h4>Alok</h4>
                                        <p>HR Manager</p>
                                    </figcaption>
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <div className="TeamBox">
                                    <figure>
                                        <img src={doemimg2} />
                                    </figure>
                                    <figcaption>
                                        <h4>Atul </h4>
                                        <p>Office Administrator Manager </p>
                                    </figcaption>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </section> */}

            {/* <section>
                <div className="MembersArea">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-3">
                                <div className="TeamBox">
                                    <figure>
                                        <img src={doemimg3} />
                                    </figure>
                                    <figcaption>
                                        <h4>Prashant Chaudhary</h4>
                                        <p>UI Lead </p>
                                    </figcaption>
                                </div>
                                <div className="TeamBox">
                                    <figure>
                                        <img src={doemimg} />
                                    </figure>
                                    <figcaption>
                                        <h4>Pradeep Gautam</h4>
                                        <p>UX | BA</p>
                                    </figcaption>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="MembersMiddle">
                                    <aside>
                                        <h4>Our UI / UX Team</h4>
                                    </aside>
                                    <article>
                                        <p>At Mobulous, we represent a well-established set of social, educational, and professional values which represent our highest ambitions for how we engage as Co-workers, Collaborators, Alumni, Associates, and Board members.</p>
                                    </article>
                                </div>
                            </div>

                            <div className="col-sm-3">
                                <div className="TeamBox">
                                    <figure>
                                        <img src={doemimg} />
                                    </figure>
                                    <figcaption>
                                        <h4>Zeeshan Alam</h4>
                                        <p>UI Designer </p>
                                    </figcaption>
                                </div>
                                <div className="TeamBox">
                                    <figure>
                                        <img src={doemimg3} />
                                    </figure>
                                    <figcaption>
                                        <h4>Mahima Kapoor</h4>
                                        <p>UX | BA</p>
                                    </figcaption>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section> */}

            <section>
                <div className="TeamArea">
                    <div className="container">
                        <div className="TitleBox">
                            <h2>Meet the <span>Dev Leads</span></h2>
                        </div>

                        <div className="row">

                            <div className="col-sm-4">
                                <div className="TeamBox jay-img-size">
                                    <figure>
                                        <img src={jay} />
                                    </figure>
                                    <figcaption>
                                        <h4>Jay Prakash</h4>
                                        <p>React Native | JavaScript | HTML,CSS</p>
                                    </figcaption>
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <div className="TeamBox jay-img-size">
                                    <figure>
                                        <img src={ravi} />
                                    </figure>
                                    <figcaption>
                                        <h4>Ravi Yadav</h4>
                                        <p>Dart | Flutter</p>
                                    </figcaption>
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <div className="TeamBox jay-img-size">
                                    <figure>
                                        <img src={bhai} />
                                    </figure>
                                    <figcaption>
                                        <h4>ALOK MANI TRIPATHI</h4>
                                        <p>Android | Node.js | Java | kotlin | JavaScript | React Js </p>
                                    </figcaption>
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <div className="TeamBox jay-img-size">
                                    <figure>
                                        <img src={me} />
                                    </figure>
                                    <figcaption>
                                        <h4>ATUL MANI TRIPATHI</h4>
                                        <p>React.JS | Full Stack Web Developer | Next Js | NodeJs | AWS | Linux </p>
                                    </figcaption>
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <div className="TeamBox jay-img-size">
                                    <figure>
                                        <img src={suraj} />
                                    </figure>
                                    <figcaption>
                                        <h4>Suraj Gupta</h4>
                                        <p>UI/UX | Graphic Designer </p>
                                    </figcaption>
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <div className="TeamBox jay-img-size">
                                    <figure>
                                        <img src={doemimg2} />
                                    </figure>
                                    <figcaption>
                                        <h4>Satyam Mishra</h4>
                                        <p>Data Analyst | Power BI | Python |  Cloud Computing | Machine Learning </p>
                                    </figcaption>
                                </div>
                            </div>






                        </div>

                    </div>
                </div>
            </section>



        </div>
    )
}

export default OurTeam;