import React, { useEffect, useState } from 'react';
import DesignHeader from './DesignHeader';
import PackageCard from './PackageCard';
import "./Design.css";
import { Helmet } from 'react-helmet';
import SEO from '../SEO';
import { db } from '../firebase'; // Import Firestore instance
import { collection, getDocs } from 'firebase/firestore';
import logo from "../img/Oglitz-main-icon.png";


const DesignPage = () => {
    const [designPackages, setDesignPackages] = useState([]);

    useEffect(() => {
        const fetchPackages = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'designPackages'));
                const packagesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setDesignPackages(packagesData);
            } catch (error) {
                console.error("Error fetching design packages: ", error);
            }
        };

        fetchPackages();
    }, []);

    const baseUrl = "https://www.oglitz.com";

    const schemaPackages = designPackages.map(pkg => ({
        "@context": "https://schema.org",
        "@type": "Product",
        "name": pkg.title,
        "image": pkg.logo[0], // Assuming the first logo is used as the main image
        "description": pkg.description,
        "keyFeatures": pkg.keyFeatures,
        "benefits": pkg.benefits,
        "moreInfo": pkg.moreInfo,
        "offers": {
            "@type": "Offer",
            "priceCurrency": "INR",
            "price": pkg.price,
            "itemCondition": "https://schema.org/NewCondition",
            "url": `${baseUrl}${pkg.link}`,
            "availability": "https://schema.org/InStock"
        },
        "additionalType": "https://schema.org/Service",
        "serviceType": pkg.benefits,
        "provider": {
            "@type": "Organization",
            "name": "Oglitz Software Pvt Ltd.",
            "url": baseUrl
        },
        "additionalInformation": {
            "duration": pkg.duration,
            "whatsIncluded": pkg.whatsIncluded,
            "moreInfo": pkg.moreInfo
        },
        "includedInPackage": pkg.whatsIncluded.map(item => ({
            "@type": "PropertyValue",
            "name": item.feature,
            "value": item.detail
        })),
        "demoImages": pkg.logo.map(url => ({
            "@type": "ImageObject",
            "url": url
        }))
    }));

    return (
        <div className="unique-design-page">
            <SEO
                title="Design Packages | Oglitz Software Pvt Ltd."
                description="Discover the premium design packages offered by Oglitz Software Pvt Ltd., including logo design, branding, and social media assets. Our packages are tailored to meet the needs of businesses looking to enhance their brand identity. Request a quote today to get started!"
                image={logo}// Update with your actual image URL
                url={`${baseUrl}/design`}
                keywords="design packages, logo design, branding, social media design, graphic design, business branding, professional design services, Oglitz Software Pvt Ltd., Oglitz Software"
                author="Oglitz Software Pvt Ltd."
                twitterHandle="@oglitz"
                schemaData={schemaPackages}
            />
            <header className="unique-header">
                <h1>Design Packages</h1>
                <p>Choose from our various design packages to fit your needs. Click on "Request Order" to get started.</p>
            </header>
            <div className="unique-packages-container">
                {designPackages.map((pkg) => (
                    <PackageCard key={pkg.id} packageData={pkg} />
                ))}
            </div>
        </div>
    );
};

export default DesignPage;
