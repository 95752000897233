import React from "react";
import { useForm } from "react-hook-form";
import "./StartProject.css";
import SEO from "../SEO";
import { toast } from "react-toastify";
// import coverImage from "./img/coverImage.jpg";

function StartProject() {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const [budgetOptions, setBudgetOptions] = React.useState([]);
  const [isCustomBudget, setIsCustomBudget] = React.useState(false);
  const [isCustomBudgethide, setIsCustomBudgethide] = React.useState(false);
  const [SubmiBtn, setSubmiBtn] = React.useState(false);

  
  //   info@oglitz.com

  // formData.append("access_key", "41c2c80f-1527-4cbd-8101-2f4c5dd07683");

  //     const response = await fetch("https://api.web3forms.com/submit", {
  //       method: "POST",
  //       body: formData
  //     });

  const softwareOptions = {
    "Mobile App": { min: 20000, max: 100000 },
    "Web App": { min: 30000, max: 150000 },
    Backend: { min: 25000, max: 120000 },
    "E-commerce": { min: 50000, max: 200000 },
    CRM: { min: 40000, max: 180000 },
    ERP: { min: 60000, max: 250000 },
    "Native Apps (iOS, Android)": { min: 30000, max: 150000 },
    "Hybrid Apps": { min: 25000, max: 120000 },
    "Cross-Platform Apps": { min: 30000, max: 140000 },
    "Web App": { min: 30000, max: 150000 },
    "Responsive Web Apps": { min: 25000, max: 130000 },
    "Progressive Web Apps (PWAs)": { min: 30000, max: 140000 },
    "Single Page Applications (SPAs)": { min: 20000, max: 120000 },
    "API Development": { min: 20000, max: 100000 },
    "Database Management": { min: 25000, max: 120000 },
    "Server-Side Development": { min: 30000, max: 130000 },
    "Enterprise Resource Planning (ERP)": { min: 60000, max: 250000 },
    "Customer Relationship Management (CRM)": { min: 40000, max: 180000 },
    "Business Intelligence (BI)": { min: 50000, max: 200000 },
    "Cloud Storage": { min: 20000, max: 100000 },
    "Cloud Computing": { min: 25000, max: 120000 },
    "Software as a Service (SaaS)": { min: 30000, max: 130000 },
    "Application Maintenance": { min: 15000, max: 80000 },
    "Technical Support": { min: 15000, max: 80000 },
    "Software Consulting": { min: 20000, max: 100000 },
    "Custom Software Development": { min: 30000, max: 150000 },
    "Manual Testing": { min: 20000, max: 100000 },
    "Automated Testing": { min: 25000, max: 120000 },
    "Performance Testing": { min: 20000, max: 100000 },
    "UI/UX Design": { min: 20000, max: 100000 },
    Prototyping: { min: 15000, max: 80000 },
    "System Integration": { min: 25000, max: 120000 },
    "API Integration": { min: 20000, max: 100000 },
    "Cybersecurity Services": { min: 30000, max: 130000 },
    "Data Encryption": { min: 20000, max: 100000 },
    "Tailored Software Solutions": { min: 30000, max: 150000 },
    "Specialized Software Development": { min: 30000, max: 140000 },
    Other: { min: 15000, max: 80000 },
  };

  const handleSoftwareTypeChange = (e) => {
    const value = e.target.value;
    if (value === "Other") {
      setBudgetOptions([]);
      setIsCustomBudgethide(true);
    } else {
      const { min, max } = softwareOptions[value] || {};
      if (min && max) {
        setBudgetOptions([
          { min: min, max: max, label: `₹${min} - ₹${max}` },
          { min: "", max: "", label: "Custom Budget" },
        ]);
        setIsCustomBudgethide(false);
      } else {
        setBudgetOptions([]);
      }
    }
  };

  const handleBudgetChange = (e) => {
    const value = e.target.value;
    if (value === "Custom Budget") {
      setIsCustomBudget(true);
    } else {
      const [min, max] = value.split("-").map((v) => v.trim().replace("₹", ""));
      setValue("minBudget", min);
      setValue("maxBudget", max);
      setIsCustomBudget(false);
    }
  };

  const onSubmit = (data) => {
    console.log("datadatadatadatadatadata===",data)
    setSubmiBtn(true)
    const { name, email, phone, projectDetails, softwareType, otherSoftwareType, minBudget, maxBudget, appointment,address ,whatsapp,projectLink} = data;
    const access_key = "41c2c80f-1527-4cbd-8101-2f4c5dd07683"; // Use your actual access key here

    if (name && email && phone && projectDetails && access_key && softwareType) {
      let formData = {
        subject:`New Project Inquiry from ${name} - Oglitz Software`,
        name,
        email,
        phone,
        message: projectDetails,
        access_key,
        organization: softwareType === "Other" ? otherSoftwareType : softwareType,
        softwareType,
        minBudget,
        maxBudget,
        appointment,
        address,
        whatsapp,
        projectLink,
      };

      // Add budget details if available
      if (minBudget && maxBudget) {
        formData.budget = `₹${minBudget} - ₹${maxBudget}`;
      }
      fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((data) => {
          setSubmiBtn(false)
          toast.success("Thank you for reaching out! Our team will be in touch shortly.");
          // alert("Thank you for reaching out! Our team will be in touch shortly.");
          // Reset form fields
          setValue("name", "");
          setValue("email", "");
          setValue("phone", "");
          setValue("whatsapp", "");
          setValue("address", "");
          setValue("projectLink", "");
          setValue("projectDetails", "");
          setValue("minBudget", "");
          setValue("maxBudget", "");
          setValue("softwareType", "");
          setValue("otherSoftwareType", "");
          setValue("appointment", "");
          setBudgetOptions([]);
        })
        .catch((error) => {
          setSubmiBtn(false)
          console.error("Error:", error);
        });
    } else {
      setSubmiBtn(false)
      toast.error("Please fill all the details. Thanks");
      // alert("Please fill all the details. Thanks");
    }
  };

  return (
    <div className="start-project">
      <SEO
        title="Start Your Project with Oglitz Software"
        description="Fill out the form to start your project with Oglitz Software. We will get back to you within 4 hours."
        // image={coverImage} // Replace with actual image URL
        url="https://www.oglitz.com/start-project"
        type="website"
        keywords="
    start project, software development, software company, IT services,
    custom software development, project management, mobile app development,
    web app development, IT consulting, software solutions, technology solutions,
    software development company, software engineering, enterprise software,
    application development, software design, technology consulting, software project,
    IT project, technology development, digital solutions, agile development,
    product development, tech solutions, software development services, app development,
    software development firm, IT firm, software consultancy, tech company
  "
      />

      <div className="header">
        <h1>Start Your Project</h1>
        <p>
          Fill out the details below, and we'll get back to you within 4 hours.
          Let us know how we can assist you in bringing your project to life!
        </p>
      </div>

      <div className="form-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Form fields */}
          <div className="form-group">
            <label>Your Name</label>
            <input
              type="text"
              {...register("name", { required: "Name is required" })}
            />
            {errors.name && (
              <p className="error-message">{errors.name.message}</p>
            )}
          </div>
          <div className="form-group">
            <label>Email Address</label>
            <input
              type="email"
              {...register("email", { required: "Email is required" })}
            />
            {errors.email && (
              <p className="error-message">{errors.email.message}</p>
            )}
          </div>
          <div className="form-group">
            <label>Phone Number</label>
            <input
              type="tel"
              {...register("phone", { required: "Phone number is required" })}
            />
            {errors.phone && (
              <p className="error-message">{errors.phone.message}</p>
            )}
          </div>
          <div className="form-group">
            <label>WhatsApp Number (Optional)</label>
            <input type="tel" {...register("whatsapp")} />
          </div>
          <div className="form-group">
            <label>Address (Optional)</label>
            <input type="text" {...register("address")} />
          </div>
          <div className="form-group">
            <label>Project Reference Link or How We Do (Optional)</label>
            <input type="text" {...register("projectLink")} />
          </div>
          <div className="form-group">
            <label>Project Details</label>
            <textarea
              {...register("projectDetails", {
                required: "Project details are required",
              })}
            ></textarea>
            {errors.projectDetails && (
              <p className="error-message">{errors.projectDetails.message}</p>
            )}
          </div>
          <div className="form-group">
            <label>Software Type</label>
            <select
              {...register("softwareType", {
                required: "Software type is required",
              })}
              onChange={(e) => {
                handleSoftwareTypeChange(e);
                e.target.value === "Other" && setValue("otherSoftwareType", "");
              }}
            >
              <option value="">Select Software Type</option>
              {Object.keys(softwareOptions).map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
            {errors.softwareType && (
              <p className="error-message">{errors.softwareType.message}</p>
            )}
            {isCustomBudgethide && (
              <div className="form-group">
                <label>Other Software Type</label>
                <input
                  type="text"
                  {...register("otherSoftwareType", {
                    required: "Other software type is required",
                  })}
                />
                {errors.otherSoftwareType && (
                  <p className="error-message">
                    {errors.otherSoftwareType.message}
                  </p>
                )}
              </div>
            )}
          </div>
          {isCustomBudgethide ? (
            <p className="budget-message">
              For 'Other' software types, we’ll discuss the project budget via
              call.
            </p>
          ) : (
            <div className="form-group">
              <label>Budget</label>
              <select
                {...register("budgetOption")}
                onChange={handleBudgetChange}
                value={
                  isCustomBudget
                    ? "Custom Budget"
                    : `${
                        getValues("minBudget")
                          ? `₹${getValues("minBudget")} - ₹${getValues(
                              "maxBudget"
                            )}`
                          : ""
                      }`
                }
              >
                {budgetOptions.map((option) => (
                  <option key={option.label} value={option.label}>
                    {option.label}
                  </option>
                ))}
              </select>
              {isCustomBudget && (
                <div className="custom-budget">
                  <label>Min Budget</label>
                  <input
                    type="number"
                    {...register("minBudget", { valueAsNumber: true })}
                  />
                  <label>Max Budget</label>
                  <input
                    type="number"
                    {...register("maxBudget", { valueAsNumber: true })}
                  />
                </div>
              )}
            </div>
          )}
          <div className="form-group">
            <label>Do you want an appointment?</label>
            <select
              {...register("appointment", {
                required: "Please select an appointment option",
              })}
            >
              <option value="">Select Option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            {errors.appointment && (
              <p className="error-message">{errors.appointment.message}</p>
            )}
          </div>
          <button className="buttonSubmitStartProject" type="submit" disabled={SubmiBtn}>Submit</button>
        </form>
      </div>

      <div className="company-info">
        <h3>Contact Us</h3>
        <p>
          Email: <a href="mailto:info@oglitz.com">info@oglitz.com</a>
        </p>
        <p>
          Support Email:{" "}
          <a href="mailto:support@oglitz.com">support@oglitz.com</a>
        </p>
        <p>
          Mobile Number: <a href="tel:+919532119007">+91 95321 19007</a>
        </p>
        <p>
          Address: 811/78 yadav nagar ahirwan, Katrisafipur, Kanpur Nagar, Uttar Pradesh - 208007
        </p>
      </div>
    </div>
  );
}

export default StartProject;
