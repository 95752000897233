import React, { useState } from 'react';
import Modal from 'react-modal';
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { db } from '../firebase'; // Ensure you import your Firebase config
import { collection, addDoc } from 'firebase/firestore';

Modal.setAppElement('#root'); // Set the root element for accessibility

const CustomModal = ({ isOpen, onRequestClose, modalData }) => {
    const [formData, setFormData] = useState({ username: '', email: '', phone: '', description: '' });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async () => {
        setLoading(true);
        const access_key = "41c2c80f-1527-4cbd-8101-2f4c5dd07683"; // Use your actual access key here

        if (formData.username && formData.email && formData.phone) {
            const formDataForAPI = {
                subject: `New Order: ${modalData?.title} Package by ${formData?.username} - Oglitz Software`,
                name: formData?.username,
                email: formData?.email,
                phone: formData?.phone,
                message: formData?.description,
                access_key,
                packageId: modalData?.id, // Include packageId here
                packageName: modalData?.title,
                packagePrice: modalData?.price,
                packageDuration: modalData?.duration,
                packageDescription: modalData?.description,
                packageBenefits: modalData?.benefits,
            };

            try {
                // Send data to the third-party API
                const response = await fetch("https://api.web3forms.com/submit", {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formDataForAPI),
                });

                if (response.ok) {
                    // Save data to Firebase
                    const orderRef = collection(db, 'designOrders');
                    await addDoc(orderRef, formDataForAPI);
                    toast.success("Thank you for reaching out! Our team will be in touch shortly.");
                    setFormData({ username: '', email: '', phone: '', description: '' });
                    onRequestClose();
                } else {
                    const errorData = await response.json();
                    toast.error(`Failed to submit the form: ${errorData.message}`);
                }
            } catch (error) {
                toast.error(`An error occurred: ${error.message}`);
                console.error('Error submitting form:', error);
            } finally {
                setLoading(false);
            }
        } else {
            toast.error("Please fill all the details.");
            setLoading(false);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Request Design Package"
        >
            <h2>Request Design Package</h2>
            <Form>
                <Form.Group controlId="formusername">
                    <Form.Label>Your Name *</Form.Label>
                    <Form.Control
                        type="text"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>
                <Form.Group controlId="formEmail">
                    <Form.Label>Email *</Form.Label>
                    <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>
                <Form.Group controlId="formPhone">
                    <Form.Label>Phone Number *</Form.Label>
                    <Form.Control
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>
                <Form.Group controlId="formDescription">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                    />
                </Form.Group>
                <div className='ModalButtonStyle'>
                    <Button
                        variant="primary"
                        className='submitButtonStyle'
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? 'Submitting...' : 'Submit'}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={onRequestClose}
                        disabled={loading}
                    >
                        Close
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default CustomModal;
