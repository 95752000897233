import React from "react";
import { Link } from "react-router-dom";
import "./PageNotFound.css";

const PageNotFound = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h1 className="title404NotFound">404 😞</h1>
        <p className="message">
          Sorry, the page you’re looking for does not exist.
        </p>
        <div className="buttons">
          <Link to={"/"} className="button home-button">
            Go to Home
          </Link>
          <a
            href="https://blog.oglitz.com/"
            className="button primaryButton"
            target="_blank"
            rel="noopener noreferrer"
          >
            Go to Blogs
          </a>
        </div>
      </div>
      {/* <div className="footer">Powered by Oglitz Software</div> */}
    </div>
  );
};

export default PageNotFound;
